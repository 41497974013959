import React, { Component, useEffect, useState } from 'react';
/*import '../assets/css/store.css';*/
import he from 'he';
import alertify from 'alertifyjs';
import StoreHead from './subComponents/StoreHead';
import StoreFooter from './subComponents/StoreFooter';
function Store({langData}) {
    const parser = new DOMParser();
    const pp = require('../assets/images/klogo.webp');
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let stockimg = localStorage.getItem('stockimg');
    let localDocs = localStorage.getItem('localDocs');
    const [getCart, setGetCart] = useState([]);
    const fetchGetCart = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions?islem=getCart&userid='+userid)
            .then(responseCart => responseCart.json())
            .then(getCart => setGetCart(getCart))
            .catch(errorCart => console.error('Fetch error : ', errorCart));
    }
    useEffect(() => {
        fetchGetCart();
    }, []);

    const cartFunc = async (cartid, userid, type) => {
        let quantity = "0";
        
        if(type != "coupon"){quantity = document.getElementById('quantity'+cartid).value;}else{quantity = document.getElementById('coupon').value;}
        const data = { userid: userid, cartid: cartid, quantity: quantity, type:type};
        try {
            const response = await fetch(localDocs+'/rn-adaptor/sfunctions?islem=cartFunc', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            if (plJson.status == 0) { alertify.error(plJson.message, 2); }
            if (plJson.status == 1) {alertify.success(plJson.message, 2);}
            if (plJson.status == 2) {document.getElementById('cartCount').innerHTML = plJson.cart;}
            console.log(plJson);
            fetchGetCart();
        } catch (error) { console.log("An error :", error); }
        
    };
    return (
        <div className='storePage'>
            <StoreHead datas={StoreHead} langData={langData} />

            <div className='containers'>
                <div className='breadcrump'>
                    <a href='/store'>{langData.home}</a> <a> > </a> <a>{langData.cart}</a>
                </div>
                <div className='shoppingCart'>
                    <div className='scLeft'>
                        <h1>{langData.cart}</h1>
                        {getCart.products ? (
                        <table>
                            <thead>
                                <tr>
                                    <td><b>#</b></td>
                                    <td><b>{langData.name}</b></td>
                                    <td><b>{langData.price}</b></td>
                                    <td><b>{langData.quantity}</b></td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                            {getCart.products.map ( products => 
                            <tr key={products.id}>
                                <td><img onClick={()=>window.location.href='/product/'+products.id} src={products.image} /></td>
                                <td>
                                    <p onClick={()=>window.location.href='/product/'+products.id}>{(parser.parseFromString(he.decode(products.title), 'text/html')).documentElement.textContent}</p>
                                    {products.options ? (<span>{products.options}</span>):''}
                                </td>
                                {products.discount > 0 ?(
                                <td>
                                    <p className='priceNew'>{products.discount}$</p>
                                    <p style={{marginLeft: '5px'}} className='priceOld'>{products.price}$</p>
                                </td>
                                ):(
                                    <td>
                                        <p className='priceNew'>{products.price}$</p>
                                    </td>
                                )}
                                <td><input onChange={()=>cartFunc(products.id, userid, 'up')} style={{textAlign:"center", width : "50px", padding : "0 0 0 8px"}} type='number' id={'quantity'+products.id} defaultValue={products.cartCount} /></td>
                                <td><p onClick={()=>cartFunc(products.id, userid, 'del')} className='cartRemove'>x</p></td>
                            </tr>
                            )}
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                        ):'No product in your cart.'}
                    </div>
                    <div className='scRight'>
                        <form className='scrCoupon'>
                            <p>{langData.coupon_code}</p>
                            <input id='coupon' placeholder={langData.coupon_code} />
                            <a onClick={()=>cartFunc(0,userid,'coupon')}>{langData.submit}</a>
                        </form>
                        <div className='scrTotals'>
                            <div className='scrTotal'>
                                <span>{langData.total} </span>
                                <p>{getCart.total}$</p>
                            </div>
                            {getCart.coupon ?(
                            <div className='scrTotal'>
                                <span>{langData.coupon_code} </span>
                                <p>{getCart.coupon}$</p>
                            </div>
                            ):''}
                            <div className='scrTotal'>
                                <span>{langData.shipping} </span>
                                <p>{getCart.cargo}$</p>
                            </div>
                            <div className='scrTotal'>
                                <span>{langData.total} </span>
                                <p>{getCart.ttotal}$</p>
                            </div>
                        </div>
                        <a href='/checkout' className='scrCheckout'>{langData.checkout}</a>
                    </div>

                </div>

            </div>
            <StoreFooter datas={StoreFooter} langData={langData} />
        </div>
    )
}
export default Store;
