import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
function Renew() {
    
    return (
        <div className='renewPage'>
            <img src={require('../assets/images/renew.webp')} />
        </div>
    )

}
export default Renew;