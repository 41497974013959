import { initializeApp } from "firebase/app";
import {getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyA6HmRMFtQ93PnEgwIDX_J2uPmdcpUqLzo",
  authDomain: "testkinky-c940c.firebaseapp.com",
  projectId: "testkinky-c940c",
  storageBucket: "testkinky-c940c.appspot.com",
  messagingSenderId: "774909774435",
  appId: "1:774909774435:web:c70bec4c306dfb333b4656",
  measurementId: "G-CSV6BKR8YV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;