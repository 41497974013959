import React, { useEffect } from 'react';

function BrevoConversations() {
  useEffect(() => {
    (function(d, w, c) {
      w.BrevoConversationsID = '674054ba2600b8050304f1f7';
      w[c] = w[c] || function() {
        (w[c].q = w[c].q || []).push(arguments);
      };
      var s = d.createElement('script');
      s.async = true;
      s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
      if (d.head) d.head.appendChild(s);
    })(document, window, 'BrevoConversations');
  }, []);

  return null; // Dieses Component gibt selbst kein UI aus.
}

export default BrevoConversations;