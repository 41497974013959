import React, { Component, useEffect, useState, useRef } from 'react'
import he from 'he';
import alertify from 'alertifyjs';
import Left from './Left';
import Right from './Right';
function opComment(id) {
    var cArea = document.getElementById('comment' + id);
    if (cArea.style.display == "block") {
        cArea.style.display = "none";
    } else {
        cArea.style.display = "block";
    }
}
let row = 1;
let type = "all";
let category = "";
function Main( {langData} ) {
    const videoRefs = useRef([]);
    const observers = useRef([]);
    const pp = require('../assets/images/klogo.webp');
    const parser = new DOMParser();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let stockimg = localStorage.getItem('stockimg');
    let localDocs = localStorage.getItem('localDocs');
    //datas = this.props.datas;
    const [getPosts, setGetPosts] = useState([]);
    const [getCat, setGetCat] = useState([]);
    const fetchGetPosts = () => {
        fetch(localDocs+'/rn-adaptor/l-mainpage?&userid=' + userid + '&type=' + type + "&row=" + row + "&cat=" + category)
            .then(responseGPo => responseGPo.json())
            .then(getPosts => setGetPosts(getPosts))
            .catch(errorGPo => console.log('Fetch error : ', errorGPo));
    }
    const fetchGetCategory = () => {
        fetch(localDocs+'/rn-adaptor/functions.php?&islem=category')
            .then(responseCat => responseCat.json())
            .then(getCat => setGetCat(getCat),)
            .catch(errorCat => console.error('Fetch error : ', errorCat));
    }
    useEffect(() => {
        fetchGetPosts();
        fetchGetCategory();
    }, []);
    useEffect(() => {
        getPosts.forEach((_, index) => {
          const observer = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {videoRefs.current[index].play();setTimeout(() => {videoRefs.current[index].pause();}, 5000);} 
                else {videoRefs.current[index].pause();}});},
            { threshold: 0.5 }
          );
      
          if (videoRefs.current[index]) {observer.observe(videoRefs.current[index]);observers.current.push(observer);}
        });
        return () => {
          observers.current.forEach((observer) => observer.disconnect());
        };
    }, [getPosts]);
    function checkVisibility(video, index) {
    const rect = video.getBoundingClientRect();
    const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
    if (isVisible) {
        video.play();
        setTimeout(() => {
        video.pause();
        }, 5000);
    } else {
        video.pause();
    }
    }
    useEffect(() => {
        const handleScroll = () => {
          videoRefs.current.forEach((video, index) => {
            if (video) {
              checkVisibility(video, index);
            }
          });
        };
      
        window.addEventListener("scroll", handleScroll);
      
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
    }, [getPosts]); // getPosts güncellenirse tekrar gözle
      
    function seeMore(){
        row = row + 1;
        fetchGetPosts();
        //console.log(row);
    }
    const postLike = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-postLike.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    const postBook = async (userid, postid, dil) => {
        const data = { userid: userid, postid: postid, dil: dil };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-addBookmarkt.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const pbJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", pbJson);
            fetchGetPosts();
        } catch (error) { console.log("An error :", error); }
    };
    const [text, setText] = useState([]);
    const emojis = ["😈", "🪢", "⛓", "🙇", "🧶", "🧵", "➰", "🍆", "🔞", "🍑", "💦", "👅", "👄", "🍑", "+", "🍆", "= Sex time", "👇", "👉", "😏", "👈", "🍆", "👅", "💦", "🤤", "ᶠᶸᶜᵏᵧₒᵤ", "💋", "👏🏼", "🎭", "👥", "👤", "🤤", "🕯", "🔥", "👀", "🌶", "🍒", "🎬", "ᶠᶸᶜᵏᵧₒᵤ! 💋" ];
    const addEmoji = (emoji) => { setText(text + emoji); }
    const textChange = (event) => { setText(event.target.value); }
    const sendComment = async (userid, postid, dil) => {
        const tArea = document.getElementById('commentText' + postid).value;
        const data = { userid: userid, postid: postid, dil: dil, comment: tArea };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-sendComment.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
            alertify.success(plJson.message, 2);
            document.getElementById('commentText' + postid).value = "";
            setText('');
        } catch (error) { console.log("An error :", error); }
    };
    const sendTip = async (userid, dil) => {
        const tipArea = document.getElementById('tipAmount').value;
        const tipComment = document.getElementById('tipComment').value;
        const tipUser = document.getElementById('mtAreaUser').innerHTML;
        const tipPost = document.getElementById('mtAreaPost').innerHTML;
        const data = { userid: userid, dil: dil, tip: tipArea, comment: tipComment, ouser: tipUser, postid: tipPost };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-sendTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetPosts();
            document.getElementById('modalTip').style.display = "none";
            document.getElementById('tipAmount').value = "";
            document.getElementById('tipComment').value = "";
            if (plJson.status == 0) { alertify.error(plJson.message, 2); }
            if (plJson.status == 1) { alertify.success(plJson.message, 2); }

        } catch (error) { console.log("An error :", error); }
    };
    const getTip = async (userid, postid) => {
        const data = { userid: userid };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-getTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const gtJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", gtJson);
            document.getElementById('modalTip').style.display = "flex";
            document.getElementById('mtAreaImg').src = gtJson.pp;
            document.getElementById('mtAreaP').innerHTML = gtJson.name + ' ' + gtJson.surname;
            document.getElementById('mtAreaA').href = '/user-detail' + gtJson.userid;
            document.getElementById('mtAreaA').innerHTML = '@' + gtJson.username;
            document.getElementById('mtAreaUser').innerHTML = userid;
            document.getElementById('mtAreaPost').innerHTML = postid;
        } catch (error) { console.log("An error :", error); }
    };
    const buyPost = async (userid, postid, dil) => {
        const data = {userid : userid, postid : postid, dil : dil}
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-buyPost.php',{
                method : 'POST', headers: {'Content-Type': 'application/json',},body: JSON.stringify(data)
            });
            if(!response.ok){throw new Error('HTTP error! status : ${response.status}');}
            const bpJson = await response.json();
            if (bpJson.status == 0) { alertify.error(bpJson.message, 2); }
            if (bpJson.status == 1) { alertify.success(bpJson.message, 2); }
            fetchGetPosts();
        }catch(error){console.log("An error : ", error);}
    }
    function chType(gType){
        document.getElementById(type+'B').classList.remove('midAreaFilterActive');
        document.getElementById(gType+'B').classList.add('midAreaFilterActive');
        type = gType;
        fetchGetPosts();
    }
    let latestPfm = "0";
    function opPfm(gPfmId){
        if(latestPfm != "0"){document.getElementById(latestPfm).style.display = "none";}
        if(latestPfm != gPfmId){document.getElementById(gPfmId).style.display = "block";latestPfm = gPfmId;}else{latestPfm = "0";}
    }
    function opReport(rUserid, rType, rPostid, rpostTitle){
        //console.log(rUserid + ' - '+ rType + ' - ' + rPostid + ' - '+rpostTitle);
        document.getElementById('reportType').innerHTML = rType;
        document.getElementById('reportUser').innerHTML = rUserid;
        document.getElementById('reportPost').innerHTML = rPostid;
        document.getElementById('reportPostTitle').innerHTML = 'Post : '+(parser.parseFromString(he.decode(rpostTitle), 'text/html')).documentElement.textContent;
        document.getElementById('reportForm').style.display = 'flex';
        document.getElementById('reportMessage').value="";
        //console.log('opRep');
    }
    const sendReport = (userid, lang) => {
        let repMsg = document.getElementById('reportMessage').value;
        let repUser = document.getElementById('reportUser').innerHTML;
        let repPost = document.getElementById('reportPost').innerHTML;
        //console.log(userid + ' - '+ repUser + ' - ' + repPost + ' - '+ repMsg);
        const formData = new FormData();
        formData.append('userid', userid); formData.append('msgid', repPost); formData.append('repUser', repUser); formData.append('msg', repMsg);
        fetch(localDocs+'/rn-adaptor/functions.php?islem=repPost', { method: 'POST', body: formData }).
        then(response => {
            if (response.status == 200) { alertify.success('Your report sended.', 2); }
            document.getElementById('reportForm').style.display = "none";
        })
        .catch(error => { console.log(error); });
    };
    function mcaFunc(){
        if(document.getElementById('mCategoryArea').style.display == "block"){
            document.getElementById('mCategoryArea').style.display = "none";
        }else{
            document.getElementById('mCategoryArea').style.display = "block";
        }
    }
    function catCh(gCat){
        category = gCat;
        fetchGetPosts();
    }
    return (
        <div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                <div className='midArea'>
                    <div id="modalTip" className="modalTip">
                        <a onClick={() => document.getElementById('modalTip').style.display = "none"} className='mmClose'></a>
                        <div className='mtArea'>
                            <div className='mtaLogo'><img src={require('../assets/images/klogo_dark.webp')}></img></div>
                            <h5>Send Tip</h5>
                            <span onClick={() => document.getElementById('modalTip').style.display = "none"}>x</span>
                            <div className='mtUserArea'>
                                <img id="mtAreaImg" src={stockimg} />
                                <p id="mtAreaP">Name Surname</p>
                                <a href={'/user-detail/1'} id='mtAreaA'>@username</a>
                            </div>
                            <p id='mtAreaUser'>0</p>
                            <p id='mtAreaPost'>0</p>
                            <div className='mtAmountArea'>
                                <p>{langData.amount}</p>
                                <img src={require('../assets/icons/tip.webp')} />
                                <input type='number' id="tipAmount" />
                            </div>
                            <div className='mtDescArea'>
                                <p>{langData.message}</p>
                                <textarea id='tipComment'></textarea>
                            </div>
                            <div className='mtButtonArea'>
                                <a>{langData.cancel}</a>
                                <a onClick={() => sendTip(userid, dil)}>{langData.send_tip}</a>
                            </div>
                        </div>
                    </div>
                    <div id="reportForm" className="reportForm">
                        <a onClick={() => document.getElementById('reportForm').style.display = "none"} className='mmClose'></a>
                        <div className='mtArea'>
                            <h5>{langData.report_form}</h5>
                            <span onClick={() => document.getElementById('reportForm').style.display = "none"}>x</span>
                            <div className='mtUserArea'>
                                <p id="reportPostTitle">Post Title</p>
                            </div>
                            <p style={{display : "none"}} id='reportType'>0</p>
                            <p style={{display : "none"}} id='reportUser'>0</p>
                            <p style={{display : "none"}} id='reportPost'>0</p>
                            <div style={{display: "none"}} className='mtAmountArea'>
                                <p>{langData.title}</p>
                                <img src={require('../assets/icons/tip.webp')} />
                                <input id="reportTitle" placeholder={langData.title} />
                            </div>
                            <div className='mtDescArea'>
                                <p>{langData.message}</p>
                                <textarea id='reportMessage' placeholder={langData.message}></textarea>
                            </div>
                            <div className='mtButtonArea'>
                                <a onClick={() => document.getElementById('reportForm').style.display = "none"}>{langData.cancel}</a>
                                <a onClick={() => sendReport(userid, dil)}>{langData.send}</a>
                            </div>
                        </div>
                    </div>
                        <div className='midAreaC'>
                            <div className='midAreaTop'>
                                {getPosts ?(<h1>Home {getPosts.count}</h1>):(<h1>Home</h1>)}
                                <a style={{display:"none"}} href=''><img src={require('../assets/icons/threedots.webp')} alt={'Home'} /></a>
                            </div>
                            <div className='midAreaFilter'>
                                <a onClick={()=>chType('all')} id='allB' className='midAreaFilterActive'>{langData.all}</a>
                                <a onClick={()=>chType('for_you')} id='for_youB'>{langData.for_you}</a>
                                <a onClick={()=>chType('purchases')} id='purchasesB'>{langData.purchases}</a>
                                <span onClick={()=>mcaFunc()}><img src={require('../assets/icons/category.webp')}></img>
                                    <div className='mCategoryArea' id='mCategoryArea'>
                                    {getCat ? (
                                    <div className='mcaOut'>
                                        <div onClick={()=>catCh('0')} className='mcaAll'>
                                            <img src={stockimg}></img>
                                            <span>All</span>
                                        </div>
                                        {getCat.map(category =>
                                            <div key={category.id} onClick={()=>catCh(category.id)} className='mcaIn'>
                                                <img src={category.image}></img>
                                                <span>{category.title}</span>
                                            </div>
                                        )}
                                    </div>
                                ) : ''}
                                    </div>
                                </span>
                            </div>
                            {getPosts ? (
                            <div className='midAreaContents'>
                                {getPosts.map((product, index) =>
                                    <div key={product.id} className='midAreaContent'>
                                        <div className='macUserInfo'>
                                            <img src={product.pp} alt={product.title} />
                                            <p>{product.name} {product.surname}</p>
                                            <a href={'/user-detail/' + product.userid}>@{product.username}</a>
                                            <div className='postFunctions'>
                                                <img onClick={()=>opPfm('pfm'+product.id)} src={require('../assets/icons/threedots.webp')}/>
                                                <div className='postFunctionsMenu' id={'pfm'+product.id}>
                                                    <span onClick={()=>window.location.href='/post/'+product.id}>Post Detail</span>
                                                    <span onClick={()=>opReport(product.userid, 'post', product.id, product.title)}>Report Post</span>
                                                </div>
                                            </div>
                                        </div>

                                        {product.image ? (
                                            <div className='macContentImage'>
                                                <div>
                                                    {(() => {
                                                        if (product.cansee === 'see') {
                                                            return <a href={'/post/'+product.id}><img src={product.image} loading="lazy" /></a>;
                                                        } else if (product.cansee === 'hide') {
                                                            return <div>
                                                                <img src={stockimg}/>;
                                                                <div className='unLockPost'>
                                                                    {product.issubs ? (
                                                                        <a href={'/user-detail/' + product.userid}>Unlock the Content by Subscribe {product.username}</a>
                                                                    ) : (
                                                                        <a onClick={()=>{buyPost(userid, product.id, dil)}}>Unlock This Content for {product.price} $</a>
                                                                    )}

                                                                </div>
                                                            </div>
                                                        } else {
                                                            return <img src={stockimg} />
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                        ) : ('')}
                                        {product.video ? (
                                            <div className='macContentVideo'>
                                                {product.cansee == 'see' ? (
                                                    <div>
                                                    <video width="100%" muted loop height="auto" style={{ maxHeight: '800px' }} controls controlsList="nodownload"
                                                      ref={(el) => (videoRefs.current[index] = el)}
                                                    >
                                                      <source src={product.video} type="video/mp4" />
                                                    </video>
                                                  </div>
                                                ) : (
                                                    <div>
                                                        <img src={stockimg} />;
                                                        <div className='unLockPost'>
                                                            {product.issubs > 0 ? (
                                                                <a href={'/user-detail/' + product.userid}>Unlock the Content by Subscribe {product.username}</a>
                                                            ) : (
                                                                <a onClick={()=>{buyPost(userid, product.id, dil)}}>Unlock This Content for {product.price} $</a>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : ('')}
                                        {product.ares1 && product.ares5 == '' ? (
                                            <div className='macContentImages'>
                                                {product.cansee == 'see' ? (
                                                    <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={product.ares1} /></div>
                                                ) : (
                                                    <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={stockimg} /></div>
                                                )}

                                                {(() => {
                                                    if (product.cansee === 'see' && product.ares2 != '') {
                                                        return <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={product.ares2} /></div>;
                                                    } else if (product.cansee === 'hide') {
                                                        <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={stockimg} /></div>
                                                    } else {
                                                        <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={stockimg} /></div>
                                                    }
                                                })()}
                                                {(() => {
                                                    if (product.cansee === 'see' && product.ares3 != '') {
                                                        return <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={product.ares3} /></div>;
                                                    } else if (product.cansee === 'hide') {
                                                        <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={stockimg} /></div>
                                                    } else {
                                                        <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={stockimg} /></div>
                                                    }
                                                })()}
                                                {(() => {
                                                    if (product.cansee === 'see' && product.ares4 != '') {
                                                        return <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={product.ares4} /></div>;
                                                    } else if (product.cansee === 'hide') {
                                                        <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={stockimg} /></div>
                                                    } else {
                                                        <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={stockimg} /></div>
                                                    }
                                                })()}

                                            </div>
                                        ) : ('')}
                                        {product.ares2 && product.ares5 != '' ? (
                                            <div className='macContentImages'>
                                                {product.cansee == 'see' ? (
                                                    <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={product.ares2} /></div>
                                                ) : (
                                                    <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={stockimg} /></div>
                                                )}

                                                {(() => {
                                                    if (product.cansee === 'see' && product.ares3 != '') {
                                                        return <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={product.ares3} /></div>;
                                                    } else if (product.cansee === 'hide') {
                                                        <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={stockimg} /></div>
                                                    } else {
                                                        <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={stockimg} /></div>
                                                    }
                                                })()}
                                                {(() => {
                                                    if (product.cansee === 'see' && product.ares4 != '') {
                                                        return <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><span>+{product.tares}</span><img src={product.ares4} /></div>;
                                                    } else if (product.cansee === 'hide') {
                                                        <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={stockimg} /></div>
                                                    } else {
                                                        <div onClick={()=>window.location.href = '/post/'+product.id} className='moreImages'><img src={stockimg} /></div>
                                                    }
                                                })()}

                                            </div>
                                        ) : ('')}
                                        <div className='macContentFunctions'>
                                            {product.ulike > 0 ? (
                                                <a onClick={() => postLike(userid, product.id, dil)} className='macContentFunction'><img src={require('../assets/icons/heartdoll.webp')} /><p>{product.like}</p></a>
                                            ) : (
                                                <a onClick={() => postLike(userid, product.id, dil)} className='macContentFunction'><img src={require('../assets/icons/heart.webp')} /><p>{product.like}</p></a>
                                            )}
                                            <a onClick={() => opComment(product.id)} className='macContentFunction'><img src={require('../assets/icons/comment.webp')} /></a>
                                            <a onClick={() => { getTip(product.userid, product.id, product.pp, product.username, product.name, product.surname) }} className='macContentFunction'><img src={require('../assets/icons/tip.webp')} /></a>
                                            {product.ubook > 0 ? (
                                                <a onClick={() => postBook(userid, product.id, dil)} className='macContentBookmark'><img src={require('../assets/icons/bookmarkdoll.webp')} /></a>
                                            ) : (
                                                <a onClick={() => postBook(userid, product.id, dil)} className='macContentBookmark'><img src={require('../assets/icons/bookmark.webp')} /></a>
                                            )}
                                            <div id={"tipArea" + product.id} className='macContentTipArea'>
                                                <select id={"tipSelect" + product.id}>
                                                    <option value="1">1$</option>
                                                    <option value="2">2$</option>
                                                    <option value="5">5$</option>
                                                    <option value="10">10$</option>
                                                    <option value="20">20$</option>
                                                    <option value="50">50$</option>
                                                </select>
                                                <a onClick={() => sendTip(userid, product.id, dil)}>Send</a>
                                            </div>
                                        </div>
                                        
                                        <div className='macContentDesc'>
                                            <h6>{(parser.parseFromString(he.decode(product.title), 'text/html')).documentElement.textContent}</h6>
                                            {(parser.parseFromString(he.decode(product.description), 'text/html')).documentElement.textContent}
                                        </div>
                                        <div id={'comment' + product.id} className='macContentComments'>
                                            {product.comments ? (
                                                <div>
                                                    {product.comments.map(comment =>
                                                        <div key={comment.id} className='macContentComment'>
                                                            <img src={comment.pp} />
                                                            <p>{comment.name} {comment.surname}</p>
                                                            <a href={'/user-dteail/' + comment.id}>@{comment.username}</a>
                                                            <div className='macContentCommentData'>
                                                                {comment.comment}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : ('')}
                                            <div className='macContentAddComment'>
                                                <h5>Add Comment</h5>
                                                <div id={"emoji" + product.id} className='macContentEmojiArea'>
                                                    {emojis.map((emoji, index) => (
                                                        <a key={`${emoji}-${index}`} onClick={() => addEmoji(emoji)} style={{ margin: '5px' }}>{emoji}</a>
                                                    ))}
                                                </div>

                                                <div className='macContentTextArea'>
                                                    <textarea id={'commentText' + product.id} value={text} onChange={textChange} rows="4" cols="50"></textarea>
                                                    <a onClick={() => { document.getElementById('emoji' + product.id).style.display === "block" ? (document.getElementById('emoji' + product.id).style.display = "none") : (document.getElementById('emoji' + product.id).style.display = "block") }
                                                    } className='emoji'><img src={require("../assets/icons/emoji.webp")} /></a>
                                                    <a onClick={() => sendComment(userid, product.id, dil)} className='send'><img src={require("../assets/icons/send.webp")} /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <a onClick={()=>seeMore()} className='seeMore'>See More</a>
                            </div>
                            ):(
                                <div className='midAreaContent'>
                                    <div className='midAreaFilter'>
                                        <p>No post..</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    <div className='rightAreaC'>
                        <Right />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Main;