import React, { Component, useEffect, useState } from 'react'
import { ScrollRestoration, UNSAFE_DataRouterStateContext, useParams } from 'react-router-dom';
import he from 'he';
import alertify from 'alertifyjs';
import Left from './Left';
function Notification({ langData }) {
    let { uniqid } = useParams();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let localDocs = localStorage.getItem('localDocs');
    const [getMessages, setGetMessages] = useState([]);
    const fetchGetMessages = () => {
        fetch(localDocs+'/rn-adaptor/l-messages?&userid=' + userid + '&status=' + status + '&orderby=' + orderby)
            .then(responseMsg => responseMsg.json())
            .then(getMessages => setGetMessages(getMessages))
            .catch(errorMsg => console.error('Fetch error : ', errorMsg));
    }
    let status = "all";
    let orderby = "desc";
    const [getMessage, setGetMessage] = useState([]);
    const fetchGetMessage = () => {
        fetch(localDocs+'/rn-adaptor/l-message?&userid=' + userid + '&uniqid=' + uniqid)
            .then(responseMs => responseMs.json())
            .then(getMessage => {setGetMessage(getMessage);fetchGetMessages();})
            .catch(errorMs => console.error('Fetch error : ', errorMs));
    }

    useEffect(() => {
        fetchGetMessage();
        fetchGetMessages();
        if (getMessage) { scLast(); }
    }, []);
    console.log(getMessages);
    
    function scLast() {
        document.getElementById('nmMessage').scrollTo({
            top: 9999, 
            behavior: 'smooth',
        });
    }
    let lastArea = "eaUsers";
    function opArea(area) {
        document.getElementById(lastArea + "Tab").classList.remove('ntfFilterActive');
        document.getElementById(area + "Tab").classList.add('ntfFilterActive');
        lastArea = area;
    }
    let yuklendimi = 0;
    if (getMessage != "") {
        setTimeout(function () {
            if (yuklendimi == 0) {
                yuklendimi = 1;
                if (getMessage) {
                    if (getMessage[0].redirect != "now") {
                        console.log(getMessage[0].redirect);
                        window.location.href = '/message/' + getMessage[0].redirect;
                    }
                }
            }
        }, 200);
    }
    let stockimg = sessionStorage.getItem('stockimg');
    function showTrash(msgid) { document.getElementById('trashArea' + msgid).style.right = '0px'; }
    function hideTrash(msgid) { document.getElementById('trashArea' + msgid).style.right = '-60px'; }
    function showDate(msgid) { document.getElementById('date' + msgid).style.right = '0px'; }
    function hideDate(msgid) { document.getElementById('date' + msgid).style.right = '-60px'; }
    function showDate2(msgid) { document.getElementById('date' + msgid).style.left = '0px'; }
    function hideDate2(msgid) { document.getElementById('date' + msgid).style.left = '-60px'; }

    const [text, setText] = useState([]);
    const emojis = ["😈", "🪢", "⛓", "🙇", "🧶", "🧵", "➰", "🍆", "🔞", "🍑", "💦", "👅", "👄", "🍑", "+", "🍆", "= Sex time", "👇", "👉", "😏", "👈", "🍆", "👅", "💦", "🤤", "ᶠᶸᶜᵏᵧₒᵤ", "💋", "👏🏼", "🎭", "👥", "👤", "🤤", "🕯", "🔥", "👀", "🌶", "🍒", "🎬", "ᶠᶸᶜᵏᵧₒᵤ! 💋" ];
    const addEmoji = (emoji) => { setText(text + emoji); }
    const textChange = (event) => { setText(event.target.value); }
    const sendMessage = async (userid, dil) => {
        const message = document.getElementById('messageArea').value;
        const data = { userid: userid, uniqid: uniqid, dil: dil, message: message };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-sendMessage.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            fetchGetMessages();
            fetchGetMessage();
            document.getElementById('nmMessage').scrollTo({ top: 9999, });
            //document.getElementById('nwmessage').scrollTo(9999, 0);
            //document.getElementById('nwmessage').scrollTo(0, 9999);

            //alertify.error(plJson.message, 2);
            document.getElementById('messageArea').value = "";
            setText('');
        } catch (error) { console.log("An error :", error); }
    };
    const parser = new DOMParser();
    let start = 0;
    let rfdur = 0;
    function scEvent() {
        let msgarea = document.getElementById('nmMessage');
        let mainscrl = msgarea.scrollTop;
        if (mainscrl < (msgarea.offsetHeight - 350)) {
            let rfdur = 1;
        } else {
            let rfdur = 0;
        }
    }
    setInterval(() => {
        //fetchGetMessage();
        //fetchGetMessages();
        if (start == 0) {
            scLast();
            start = 1;
        }
        scEvent();
        if (rfdur == 1) {
            alert('1');
            scLast();
        }

    }, 2000);
    const delMsg = async (userid, uniqid) => {
        //alert(userid+'-'+uniqid);
        const delData = new FormData();
        delData.append('userid', userid); delData.append('uniqid', uniqid);
        fetch(localDocs+'/rn-adaptor/functions.php?islem=delMsg', { method: 'POST', body: delData }).then(response => { console.log(response); fetchGetMessages(); }).catch(error => { console.log(error); });
    };
    const openMedia = async (road, type) => {
        document.getElementById('modalMedia').style.display = "flex";
        if (type == "video") {
            document.getElementById('mmDatasImage').style.display = "none";
            document.getElementById('mmDatasVideo').style.display = "flex";
            document.getElementById('modalVideo').src = road;
        } else {
            document.getElementById('mmDatasImage').style.display = "flex";
            document.getElementById('mmDatasVideo').style.display = "none";
            document.getElementById('modalImages').src = road;
        }
    };
    
    function mClose() {
        document.getElementById('modalMedia').style.display = "none";
        document.querySelector("#modalVideo").pause();
    }
    const sendTip = async (userid, uniqid) => {
        const tipArea = document.getElementById('tipAmount').value;
        const tipComment = document.getElementById('tipComment').value;
        const data = { userid: userid, uniqid: uniqid, tip: tipArea, comment: tipComment, };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/functions.php?islem=msgTip', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            document.getElementById('modalTip').style.display = "none";
            document.getElementById('tipAmount').value = "";
            document.getElementById('tipComment').value = "";
            if (plJson.status == 0) { alertify.error(plJson.message, 2); }
            if (plJson.status == 1) { alertify.success(plJson.message, 2); fetchGetMessage(); fetchGetMessages(); document.getElementById('nmMessage').scrollTo({ top: 9999, }); }

        } catch (error) { console.log("An error :", error); }
    };
    const buyImage = async (msgid, userid, uniqid) => {
        const data = { msgid: msgid, userid: userid, uniqid: uniqid };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/functions.php?islem=buyImage', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            //console.log("PHP tarafından dönen yanıt:", plJson);
            if (plJson.status == 0) { alertify.error(plJson.message, 2); }
            if (plJson.status == 1) { alertify.success(plJson.message, 2); fetchGetMessage(); fetchGetMessages(); document.getElementById('nmMessage').scrollTo({ top: 9999, }); }

        } catch (error) { console.log("An error :", error); }
    };

    let dosya = "";
    const handleImageChange = (event, userid, uniqid) => {
        dosya = event.target.files[0];
        const formData = new FormData();
        formData.append('file', dosya); formData.append('userid', userid); formData.append('uniqid', uniqid);
        fetch(localDocs+'/rn-adaptor/functions.php?islem=msgImageAdd', { method: 'POST', body: formData }).
            then(response => { console.log(response); })
            .catch(error => { console.log(error); });
    };
    const sendImage = (userid, uniqid) => {
        let imgAmount = document.getElementById('imgAmount').value;
        let imgComment = document.getElementById('imgComment').value;
        const formData = new FormData();
        formData.append('userid', userid); formData.append('uniqid', uniqid); formData.append('ucret', imgAmount); formData.append('mesaj', imgComment);
        fetch(localDocs+'/rn-adaptor/functions.php?islem=messageImageAdd', { method: 'POST', body: formData }).
            then(response => {
                //console.log(response);
                fetchGetMessages();
                fetchGetMessage();
                document.getElementById('nmMessage').scrollTo({ top: 9999, });
                document.getElementById('modalImg').style.display = "none";
                document.getElementById('imgAmount').value = "";
                document.getElementById('imgComment').value = "";
            })
            .catch(error => { console.log(error); });

    };
    function opMobMenu(opmmType){
        if(opmmType == "open"){
            document.getElementById('allMessages').style.left = "0px";
        }else{
            document.getElementById('allMessages').style.left = "-300px";
        }
    }
    //orderby
    let gStatus = status;
    function chStatus(gStatus){
        document.getElementById('statusBtnAll').classList.remove('amSelectActive');
        document.getElementById('statusBtnRead').classList.remove('amSelectActive');
        document.getElementById('statusBtnUnread').classList.remove('amSelectActive');
        if(gStatus == "all"){status = "all";document.getElementById('statusBtnAll').classList.add('amSelectActive');}
        if(gStatus == "read"){status = "read";document.getElementById('statusBtnRead').classList.add('amSelectActive');}
        if(gStatus == "unread"){status = "unread";document.getElementById('statusBtnUnread').classList.add('amSelectActive');}
        fetchGetMessages();
    }
    function chOrderby(){
        if(orderby == "asc"){orderby = "desc";document.getElementById('groupbyText').innerHTML = langData.older_first;}
        else{orderby = "asc";document.getElementById('groupbyText').innerHTML = langData.newest_first;}
        fetchGetMessages();
    }
    console.log(getMessages);
    return (
        <div>
            <div id="modalTip" className="modalTip">
                <a onClick={() => document.getElementById('modalTip').style.display = "none"} className='mmClose'></a>
                <div className='mtArea'>
                    <div className='mtaLogo'><img src={require('../assets/images/klogo_dark.webp')}></img></div>
                    <h5>{langData.send_tip}</h5>
                    <span onClick={() => document.getElementById('modalTip').style.display = "none"}>x</span>
                    <p id='mtAreaUser'>0</p>
                    <p id='mtAreaPost'>0</p>
                    <div className='mtAmountArea'>
                        <p>{langData.amount}</p>
                        <img src={require('../assets/icons/tip.webp')} />
                        <input id="tipAmount" placeholder={langData.amount} />
                    </div>
                    <div className='mtDescArea'>
                        <p>{langData.message}</p>
                        <textarea id='tipComment' placeholder={langData.message}></textarea>
                    </div>
                    <div className='mtButtonArea'>
                        <a onClick={() => document.getElementById('modalTip').style.display = "none"}>{langData.cancel}</a>
                        <a onClick={() => sendTip(userid, uniqid)}>{langData.send_tip}</a>
                    </div>
                </div>
            </div>
            <div id='modalMedia' className='modalMedia'>
                <a onClick={() => mClose()} className='mmClose'></a>
                <div className='mmDatas'>
                    {/*<a onClick={() => prevMedia()} className='mmDatasLeft' id='mmDatasLeft'><img src={require('../assets/icons/arrow.webp')} /></a>*/}
                    <div className='mmDatasImage' id='mmDatasImage'>
                        <img id='modalImages' src={stockimg} />
                    </div>
                    <div className='mmDatasVideo' id='mmDatasVideo'>
                        <video id='modalVideo' controls><source id='modalVideoSrc' src={stockimg} type="video/mp4" /></video>
                    </div>
                    {/*<a onClick={() => nextMedia()} className='mmDatasRight' id='mmDatasRight'><img src={require('../assets/icons/arrow.webp')} /></a>*/}
                </div>
            </div>
            <div id="modalImg" className="modalTip">
                <a onClick={() => document.getElementById('modalImg').style.display = "none"} className='mmClose'></a>
                <div className='mtArea'>
                    <h5>{langData.send_image}</h5>
                    <span onClick={() => document.getElementById('modalImg').style.display = "none"}>x</span>
                    <div className='mtAmountArea'>
                        <p>{langData.price}</p>
                        <img src={require('../assets/icons/tip.webp')} />
                        <input id="imgAmount" placeholder='Enter Amount' />
                    </div>
                    <div className='mtAmountArea'>
                        <p>{langData.image}</p>
                        <a onClick={() => {document.getElementById('modalImage').click();}}>{langData.select_image}</a>
                        <input type='file' id='modalImage' onChange={(events, s) => { handleImageChange(events, userid, uniqid); }} className='hidden' />
                    </div>
                    <div className='mtDescArea'>
                        <p>{langData.message}</p>
                        <textarea id='imgComment'></textarea>
                    </div>
                    <div className='mtButtonArea'>
                        <a>{langData.cancel}</a>
                        <a onClick={() => sendImage(userid, uniqid)}>{langData.send}</a>
                    </div>
                </div>
            </div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                <div className='amHeader'>
                    <a onClick={()=>window.history.back()} className='leftArrow webitem'><img src={require('../assets/icons/arrow.webp')} /></a>
                    <a onClick={()=>window.history.back()}  className='leftArrow mobileitem'><img src={require('../assets/icons/arrow.webp')} /></a>
                    <h3>{langData.messages}</h3>
                </div>
                <div className='allMessagesBtn' onClick={()=>opMobMenu('open')}><img src={require('../assets/icons/message.webp')}/></div>
                <div className='massagesBack'>
                    <div className='allMessages' id='allMessages'>
                        <div className='amFilter' style={{display : 'none'}}>
                            <p id='groupbyText'>{langData.newest_first}</p>
                            <img onClick={()=>chOrderby()} src={require('../assets/icons/filter.webp')} />
                        </div>
                        <div className='amSelect'>
                            <a onClick={()=>chStatus('all')} id='statusBtnAll' className='amSelectActive'>{langData.all}</a>
                            <a onClick={()=>chStatus('read')} id='statusBtnRead'>{langData.read}</a>
                            <a onClick={()=>chStatus('unread')} id='statusBtnUnread'>{langData.unread}</a>
                        </div>
                        {getMessages ? (
                            <div>
                                {getMessages.map(msgs =>
                                    <div key={msgs.id}>
                                        {msgs.del != userid ? (
                                            <div key={msgs.id} onMouseOver={() => showTrash(msgs.id)} onMouseOut={() => hideTrash(msgs.id)} className='amMessage amMessageUnread'>
                                                <img onClick={() => window.location.href = '/message/' + msgs.uniqid} className='amMessagePic' src={msgs.pp} />
                                                <div onClick={() => window.location.href = '/message/' + msgs.uniqid} className='amMessageUser'>
                                                    <p>{msgs.name} {msgs.surname}</p>
                                                    {/*<a href={'/user-detail/' + msgs.otherid}>@{msgs.username}</a>*/}
                                                    <a>@{msgs.username}</a>
                                                </div>
                                                {msgs.status > 0 ?(
                                                <div className='msgStatus'>{msgs.status}</div>
                                                ):''}
                                                <div onClick={() => window.location.href = '/message/' + msgs.uniqid} className='amMessageLast'>
                                                    <p>{msgs.lmsg}</p>
                                                    <span>{msgs.gdate}</span>
                                                </div>
                                                <div id={"trashArea" + msgs.id} onClick={() => delMsg(userid, msgs.uniqid)} className='amMessageTrash'>
                                                    <img src={require('../assets/icons/trash.webp')} />
                                                </div>
                                            </div>
                                        ) : ''}
                                    </div>
                                )}
                            </div>
                        ) : ('')}
                    </div>
                    <div className='nowMessages' onClick={()=>opMobMenu('close')}>
                        <div className='nmHeader'>
                            {uniqid && getMessage ? (
                                <div>
                                    {getMessage.length > 0 ? (
                                        <div>
                                            <img src={getMessage[0].pp} />
                                            <h3>{getMessage[0].name} {getMessage[0].surname}</h3>
                                            <a href={'/user-detail/'+getMessage[0].otherid}>@{getMessage[0].username}</a>
                                        </div>
                                    ) : ('')}
                                </div>
                            ) : ('')}
                        </div>
                        <div onScroll={() => scEvent()} className='nmMessage' id='nmMessage'>
                            {uniqid && getMessage ? (
                                <div>
                                    {getMessage.length > 0 ? (
                                        <div>
                                            {getMessage.map(msgs =>
                                                <div key={msgs.id}>
                                                    {msgs.msg ? (
                                                        <div key={msgs.id} className='nmMessageArea'>
                                                            {msgs.position == "left" ? (
                                                                <div className='nmmLeftArea'>
                                                                    {msgs.image ? (
                                                                        <div className='nmmlaArea'>
                                                                            <img onClick={() => { openMedia(msgs.image, 'image') }} className='nmmlaImage' src={msgs.image} />
                                                                            {msgs.cansee == 0 ? (
                                                                                <div>
                                                                                    <div className='nmmlaCover'></div>
                                                                                    <a className='nmmlaBtn' onClick={() => buyImage(msgs.id, userid, uniqid)}>Buy with {msgs.price}€</a>
                                                                                </div>
                                                                            ) : ''}
                                                                        </div>
                                                                    ) : ''}
                                                                    <div className='nmMessageLeft'>
                                                                        <img src={msgs.pp} />
                                                                        <div key={msgs.id} onMouseOver={() => showDate(msgs.id)} onMouseOut={() => hideDate(msgs.id)} className='nmmLeftMsg'>
                                                                            <span id={'date' + msgs.id}>{msgs.gdate}</span>
                                                                            <p>{(parser.parseFromString(he.decode(msgs.msg), 'text/html')).documentElement.textContent}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='nmmRightArea'>
                                                                    {msgs.image ? (
                                                                        <div className='nmmraArea'>
                                                                            <img onClick={() => { openMedia(msgs.image, 'image') }} className='nmmraImage' src={msgs.image} />
                                                                            {msgs.cansee == 0 ? (
                                                                                <div>
                                                                                    <div className='nmmraCover'></div>
                                                                                    <a className='nmmraBtn' onClick={() => buyImage(msgs.id, userid, uniqid)}>Buy with {msgs.price}€</a>
                                                                                </div>
                                                                            ) : ''}
                                                                        </div>
                                                                    ) : ''}
                                                                    <div className='nmMessageRight'>
                                                                        <img src={msgs.mpp} />
                                                                        <div key={msgs.id} onMouseOver={() => showDate2(msgs.id)} onMouseOut={() => hideDate2(msgs.id)} className='nmmRightMsg'>
                                                                            <span id={'date' + msgs.id}>{msgs.gdate}</span>
                                                                            <p>{(parser.parseFromString(he.decode(msgs.msg), 'text/html')).documentElement.textContent}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : ''}
                                                </div>
                                            )}
                                        </div>
                                    ) : ('')}
                                </div>
                            ) : (
                                <div className='nmMessageFree'>
                                    <p>{langData.choose_a_conversation}</p>
                                </div>
                            )}
                        </div>
                        {getMessage ? (
                            <div className='nmSendMessage'>
                                {uniqid ? (
                                    <div>
                                        <div id={"emoji1"} className='nmEmojiArea'>
                                            {emojis.map((emoji) => (
                                                <a key={emoji} onClick={() => addEmoji(emoji)} style={{ margin: '5px' }}>{emoji}</a>
                                            ))}
                                        </div>

                                        <div className='nmArea'>
                                            <textarea id={'messageArea'} value={text} onChange={textChange} placeholder='Enter your message' onClick={()=>document.getElementById('emoji1').style.display = 'none'}></textarea>
                                            <a onClick={() => { document.getElementById('emoji1').style.display === "block" ? (document.getElementById('emoji1').style.display = "none") : (document.getElementById('emoji1').style.display = "block") }
                                            } className='emoji'><img src={require("../assets/icons/messageEmoji.webp")} /></a>
                                            <span></span>
                                            <a onClick={() => { document.getElementById('modalTip').style.display = "flex"; }} className='tip'><img src={require("../assets/icons/tip.webp")} /></a>
                                            <a onClick={() => { document.getElementById('modalImg').style.display = "flex"; }} className='ipost'><img src={require("../assets/icons/messageImage.webp")} /></a>
                                            <a onClick={() => sendMessage(userid, dil)} className='send'><img src={require("../assets/icons/send.webp")} /></a>
                                        </div>
                                    </div>
                                ) : ''}
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Notification;