import React, { Component, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import he from 'he';
/*import '../assets/css/store.css';*/
import StoreHead from './subComponents/StoreHead';
import StoreFooter from './subComponents/StoreFooter';
let minPrice = 0;
let maxPrice = 999999;
let metarial = "";
function Store({langData}) {
    let { seo, search } = useParams();
    
    const parser = new DOMParser();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let stockimg = localStorage.getItem('stockimg');
    let localDocs = localStorage.getItem('localDocs');
if(seo){}else{seo = "all";}
    const [getCats, setGetCats] = useState([]);
    const [getNCats, setGetNCats] = useState([]);
    const [getProducts, setGetProducts] = useState([]);
    const fetchGetCats = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions?islem=getCat&area=all')
            .then(responseCat => responseCat.json())
            .then(getCats => setGetCats(getCats))
            .catch(errorCat => console.error('Fetch error : ', errorCat));
    }
    const fetchGetNCats = (seo, search) => {
        console.log("search : "+search);
        fetch(localDocs+'/rn-adaptor/sfunctions?islem=nowCat&category='+seo+'&searchkey='+search+'&minPrice='+minPrice+'&maxPrice='+maxPrice+'&material='+metarial)
            .then(responseNCat => responseNCat.json())
            .then(getNCats => {setGetNCats(getNCats);if(getNCats.isCat == "0"){window.location.href='/categories';}})
            .catch(errorNCat => console.error('Fetch error : ', errorNCat));
    }
    /*const fetchGetProducts = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions?islem=getCatProduct&category='+seo+'&minPrice='+minPrice+'&maxPrice='+maxPrice+'&material='+metarial)
            .then(responseProducts => responseProducts.json())
            .then(getProducts => setGetProducts(getProducts))
            .catch(errorProducts => console.error('Fetch error : ', errorProducts));
    }*/
    useEffect(() => {
        fetchGetCats(seo);
        fetchGetNCats(seo, search);
        //fetchGetProducts(seo);
    }, []);
    console.log(getNCats);
    //console.log(getNCats.description);
    const pp = require('../assets/images/klogo.webp');
    return (
        <div className='storePage'>
            <StoreHead datas={StoreHead} langData={langData} />

            <div className='containers'>
                <div className='breadcrump'>
                    <a href='/store'>{langData.home}</a> <a> &#11146; </a> 
                    {getNCats.title ?(
                        <a>{getNCats.title}</a>
                    ):(
                        <a>{langData.categories}</a>
                    )}
                </div>
                <div className='categoriesLeft'>
                    {getNCats.categories ? (
                    <div className='clCats'>
                        <h3>{langData.categories}</h3>
                        {getNCats.categories.map( tcats => 
                            <div key={tcats.id}>
                                <a href={'/categories/'+tcats.seo}>{(parser.parseFromString(he.decode(tcats.title), 'text/html')).documentElement.textContent}</a>
                                {tcats.cats ? (
                                    <div>
                                        {tcats.cats.map ( scats => 
                                        <span key={scats.id} onClick={()=> window.location.href = '/categories/'+scats.seo}>{(parser.parseFromString(he.decode(scats.title), 'text/html')).documentElement.textContent}</span>
                                        )}
                                    </div>
                                ):''}
                            </div>
                        )}
                    </div>
                    ):''}
                    {/*<h3>{langData.filter}</h3>
                    <div className='clCheckBox'>
                        <input type='checkbox' />
                        <span>In Stock</span>
                    </div>
                    <h3>{langData.price}</h3>
                    <div className='clPriceBox'>
                        <input placeholder='$ 0' type='number' />
                        <span>to</span>
                        <input placeholder='$ 9999' type='number' />
                    </div>*/}
                    {/*<h3>{langData.metarial}</h3>
                    <div className='clCheckBox'><input type='checkbox' /><span>Glass</span></div>
                    <div className='clCheckBox'><input type='checkbox' /><span>Leather</span></div>
                    <div className='clCheckBox'><input type='checkbox' /><span>Leather and Metal</span></div>
                    <div className='clCheckBox'><input type='checkbox' /><span>Metal</span></div>
                    <div className='clCheckBox'><input type='checkbox' /><span>Other</span></div>
                    <div className='clCheckBox'><input type='checkbox' /><span>Plastic</span></div>
                    <div className='clCheckBox'><input type='checkbox' /><span>Silicone</span></div>*/}
                </div>
                {getNCats ?(
                <div className='categoriesRight'>
                    {getNCats.title ?(<h1>{(parser.parseFromString(he.decode(getNCats.title), 'text/html')).documentElement.textContent}</h1>):(<h1>{langData.categories}</h1>)}
                    {getNCats.description ? (<p>{(parser.parseFromString(he.decode(getNCats.description), 'text/html')).documentElement.textContent}</p>):''}
                    {getNCats.banner ?(<h1><img className='crBanner' src={getNCats.banner} /></h1>):('')}
                    {/*<img className='crBanner' src={require('../assets/images/storeBanner.webp')} />*/}
                    {getNCats.products?(
                    <div>
                        {getNCats.products.map (products=>
                        <div key={products.id} className='crItems'>
                            <div className='crItem'>
                                <img onClick={()=>window.location.href='/product/'+products.id} src={products.image} />
                                {products.title?(<p onClick={()=>window.location.href='/product/'+products.id} >{(parser.parseFromString(he.decode(products.title), 'text/html')).documentElement.textContent} </p>):''}
                                {products.discount > 0 ? (
                                <div>
                                    <span className='priceNew'>{products.discount}$</span>
                                    <span className='priceOld'>{products.price}$</span>
                                </div>
                                ):(
                                <div>
                                    <span className='priceNew'>{products.price}$</span>
                                </div>
                                )}
                            </div>
                        </div>
                        )}
                    </div>
                    ):''}
                </div>
                ):''}
            </div>
            <StoreFooter datas={StoreFooter} langData={langData} />
        </div>
    )
}
export default Store;
