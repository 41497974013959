import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
import he from 'he';
import alertify from 'alertifyjs';
import Left from './Left';
function Notification({ langData }) {
    let { uniqid } = useParams();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let stockimg = localStorage.getItem('stockimg');
    let localDocs = localStorage.getItem('localDocs');
    const [creatorData, setCreatorData] = useState([]);
    const [allData, setAllData] = useState([]);
    let type = "all";
    let sof = "follow";
    const fetchCreatorData = () => {
        fetch(localDocs+'/rn-adaptor/o-collectionUser?id=' + userid + '&type=' + type + '&sof=' + sof)
            .then(responseCD => responseCD.json())
            .then(creatorData => setCreatorData(creatorData))
            .catch(errorCD => console.error('Fetch error : ', errorCD));
    }
    const fetchAllData = () => {
        fetch(localDocs+'/rn-adaptor/functions?islem=collectionData&id=' + userid + '&type=' + type + '&sof=' + sof)
            .then(responseAD => responseAD.json())
            .then(allData => setAllData(allData))
            .catch(errorAD => console.error('Fetch error : ', errorAD));
    }
    useEffect(() => {
        fetchCreatorData();
        fetchAllData();
    }, []);
    console.log(creatorData);
    let chCollectS = 'lists';
    function chCollect(chCollectN) {
        document.getElementById(chCollectS).style.display = "none"; document.getElementById(chCollectN).style.display = "block";
        document.getElementById(chCollectS + 'A').classList.remove('acSelectActive'); document.getElementById(chCollectN + 'A').classList.add('acSelectActive');
        chCollectS = chCollectN;
    }
    let cliLast = "follow";
    function chListItem(cliNow) {
        //document.getElementById(chCollectS).style.display = "none"; document.getElementById(chCollectN).style.display = "block";
        sof = cliNow;
        fetchCreatorData();
        document.getElementById(cliLast + 'A').classList.remove('listsItemActive'); document.getElementById(cliNow + 'A').classList.add('listsItemActive');
        cliLast = cliNow;
    }
    let ncCollectS = 'subCreators';
    function ncCollect(ncCollectN) {
        document.getElementById(ncCollectS).style.display = "none"; document.getElementById(ncCollectN).style.display = "block";
        document.getElementById(ncCollectS + 'A').classList.remove('acSelectActive'); document.getElementById(ncCollectN + 'A').classList.add('acSelectActive');
        ncCollectS = ncCollectN;
    }
    let fcCollectS = 'subCreators';
    function ncCollect(fcCollectN) {
        document.getElementById(fcCollectS).style.display = "none"; document.getElementById(fcCollectN).style.display = "block";
        document.getElementById(fcCollectS + 'A').classList.remove('acSelectActive'); document.getElementById(fcCollectN + 'A').classList.add('acSelectActive');
        fcCollectS = fcCollectN;
    }
    function opMenu(type){
        if(type == "open"){document.getElementById('searchMenu').style.right = "0";}else{document.getElementById('searchMenu').style.right = "-300px";}
    }
    const sendTip = async (userid, dil) => {
        const tipArea = document.getElementById('tipAmount').value;
        const tipComment = document.getElementById('tipComment').value;
        const tipUser = document.getElementById('mtAreaUser').innerHTML;
        const tipPost = document.getElementById('mtAreaPost').innerHTML;
        const data = { userid: userid, dil: dil, tip: tipArea, comment: tipComment, ouser: tipUser, postid: tipPost };
        //console.log(data);
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-sendTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            document.getElementById('modalTip').style.display = "none";
            document.getElementById('tipAmount').value = "";
            document.getElementById('tipComment').value = "";
            if (plJson.status == 0) { alertify.error(plJson.message, 2); }
            if (plJson.status == 1) { alertify.success(plJson.message, 2); }

        } catch (error) { console.log("An error :", error); }
    };
    
    const getTip = async (userid, postid) => {
        const data = { userid: userid };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-getTip.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const gtJson = await response.json();
            document.getElementById('modalTip').style.display = "flex";
            document.getElementById('mtAreaImg').src = gtJson.pp;
            document.getElementById('mtAreaP').innerHTML = gtJson.name + ' ' + gtJson.surname;
            document.getElementById('mtAreaA').href = '/user-detail' + gtJson.userid;
            document.getElementById('mtAreaA').innerHTML = '@' + gtJson.username;
            document.getElementById('mtAreaUser').innerHTML = userid;
            document.getElementById('mtAreaPost').innerHTML = postid;
        } catch (error) { console.log("An error :", error); }
    };
    return (
        <div>
            <div id="modalTip" className="modalTip">
                <a onClick={() => document.getElementById('modalTip').style.display = "none"} className='mmClose'></a>
                <div className='mtArea'>
                    <h5>{langData.send_tip}</h5>
                    <span onClick={() => document.getElementById('modalTip').style.display = "none"}>x</span>
                    <div className='mtUserArea'>
                        <img id="mtAreaImg" src={stockimg} />
                        <p id="mtAreaP">Name Surname</p>
                        <a href={'/user-detail/1'} id='mtAreaA'>@username</a>
                    </div>
                    <p id='mtAreaUser'>0</p>
                    <p id='mtAreaPost'>0</p>
                    <div className='mtAmountArea'>
                        <p>Tip Amount</p>
                        <img src={require('../assets/icons/tip.webp')} />
                        <input id="tipAmount" placeholder='Enter Amount' />
                    </div>
                    <div className='mtDescArea'>
                        <p>Message</p>
                        <textarea id='tipComment'></textarea>
                    </div>
                    <div className='mtButtonArea'>
                        <a>Cancel</a>
                        <a onClick={() => sendTip(userid, dil)}>{langData.send_tip}</a>
                    </div>
                </div>
            </div>
            <div className='leftAreaC' onClick={()=>opMenu('close')}>
                <Left title="Title" />
            </div>
            <div className='raFilters' id='searchMenu'>
                <a className='rafClose' onClick={()=>opMenu('close')}>x</a>
                <div className='raFilter'>
                    <h6>Filter By Username</h6>
                    <input id='username' placeholder='Type here..' />
                </div>
                <div className='raFilter'>
                    <h6>Rating</h6>
                    <select id='rating'>
                        <option value='all'>All</option>
                        <option value='sfw'>SFW</option>
                        <option value='nsfw'>NSFW</option>
                    </select>
                </div>
                <div className='raFilter'>
                    <h6>Sexual Oriantation</h6>
                    <select id='sexual'>
                        <option value='heterosexual_straight'>Heterosexual/Straight</option>
                        <option value='homosexual'>Homosexual</option>
                        <option value='bisexual'>Bisexual</option>
                        <option value='bisexual'>Bisexual</option>
                        <option value='heteroflexible'>Heteroflexible</option>
                        <option value='polysexual'>Polysexual</option>
                        <option value='queer'>Queer</option>
                        <option value='androgynosexual'>Androgynosexual</option>
                        <option value='androsexual'>Androsexual</option>
                        <option value='gray_a'>Gray-A</option>
                        <option value='gynosexual'>Gynosexual</option>
                        <option value='objectumsexual'>Objectumsexual</option>
                        <option value='pansexual_omnisexual'>Pansexual/Omnisexual</option>
                        <option value='skolisexual'>Skolisexual</option>
                    </select>
                </div>
                <div className='raFilter'>
                    <h6>Age</h6>
                    <input className='rafiDouble' type='text' id='minAge' value='18' />
                    <input className='rafiDouble' type='text' id='maxAge' value='45' />
                </div>
                <div className='raFilter'>
                    <h6>Eye Color</h6>
                    <select id='eye'>
                        <option value='all'>All</option>
                        <option value='amber'>Amber</option>
                        <option value='blue'>Blue</option>
                        <option value='brown'>Brown</option>
                        <option value='green'>Green</option>
                        <option value='grey'>Grey</option>
                        <option value='hazel'>Hazel</option>
                        <option value='red'>Red</option>
                        <option value='violet'>Violet</option>
                        <option value='mixed'>Mixed</option>
                    </select>
                </div>
                <div className='raFilter'>
                    <h6>Hair Color</h6>
                    <select id='hair'>
                        <option value='all'>All</option>
                        <option value='black'>Black</option>
                        <option value='blonde'>Blonde</option>
                        <option value='dark_blonde'>Dark Blonde</option>
                        <option value='platinum_blonde'>Platinum Blonde</option>
                        <option value='brunette'>Brunette</option>
                        <option value='dark_brown'>Dark Brown</option>
                        <option value='red_head'>Red Head</option>
                        <option value='auburn'>Auburn</option>
                        <option value='blue'>Blue</option>
                        <option value='grey'>Grey</option>
                        <option value='pin'>Pink</option>
                        <option value='purple'>Purple</option>
                        <option value='orange'>Orange</option>
                        <option value='many'>Many</option>
                    </select>
                </div>
                <div className='raFilter'>
                    <h6>Shoe Size</h6>
                    <input className='rafiDouble' type='text' id='minShoe' value='4' />
                    <input className='rafiDouble' type='text' id='maxShoe' value='14' />
                </div>
                <div className='raFilter'>
                    <h6>Height</h6>
                    <select id='height'>
                        <option value='all'>All</option>
                        <option value='little'>Little</option>
                        <option value='short'>Short</option>
                        <option value='average'>Average</option>
                        <option value='tall'>Tall</option>
                        <option value='very_tall'>Very Tall</option>
                    </select>
                </div>
                <div className='raFilter'>
                    <h6>Ethnicity</h6>
                    <select id='ethnicity'>
                        <option value='all'>All</option>
                        <option value='white_caucasian'>White/Caucasian</option>
                        <option value='latino_hispanic'>Latino/Hispanic</option>
                        <option value='asian'>Asian</option>
                        <option value='black_ebony'>Black/Ebony</option>
                        <option value='mixed'>Mixed</option>
                        <option value='native_american_alaskan'>Native American/Alaskan</option>
                        <option value='pacific_islander'>Pacific Islander</option>
                        <option value='middle_eastern'>Middle Eastern</option>
                        <option value='east_indian'>East Indian</option>
                        <option value='other'>Other</option>
                    </select>
                </div>
                <a className='rafBtn'>Search</a>
            </div>
            <div className='fullAreaC'>
                <div className='amHeader'>
                    <a onClick={()=>window.history.back()} className='leftArrow webitem'><img src={require('../assets/icons/arrow.webp')} /></a>
                    <a onClick={()=>window.history.back()}  className='leftArrow mobileitem'><img src={require('../assets/icons/arrow.webp')} /></a>
                    <h3>{langData.collections}</h3>
                </div>
                <div className='allCollections' onClick={()=>opMenu('close')}>
                    {/*<div className='acSelect'>
                        <a onClick={() => { chCollect('lists') }} id="listsA" className='acSelectActive'>Lists</a>
                        <a onClick={() => { chCollect('bookmarks') }} id="bookmarksA">Bookmarks</a>
                    </div>
                    <div className='acFilter'>
                        <p>Newest first</p>
                        <img src={require('../assets/icons/filter.webp')} />
                    </div>*/}

                    <div id='lists'>
                        <div className='listsItem listsItemActive' id="followA" onClick={()=>chListItem('follow')}>
                            <div className='listsItemData'>
                                <p>{langData.follows}</p>
                                <a>{allData.follow} people <span> | </span> {allData.followPost} posts</a>
                            </div>
                            <div className='listsItemPp'>
                                {allData.follow1 ? (<img src={allData.follow1} />):('')}{allData.follow2 ? (<img src={allData.follow2} />):('')}{allData.follow3 ? (<img src={allData.follow3} />):('')}{allData.follow4 ? (<img src={allData.follow4} />):('')}
                                {allData.follow5 ? (<img src={allData.follow5} />):('')}{allData.follow6 ? (<img src={allData.follow6} />):('')}{allData.follow7 ? (<img src={allData.follow7} />):('')}{allData.follow8 ? (<img src={allData.follow8} />):('')}
                                {allData.follow9 ? (<img src={allData.follow9} />):('')}{allData.follow10 ? (<img src={allData.follow10} />):('')}{allData.follow11 ? (<img src={allData.follow11} />):('')}{allData.follow12 ? (<img src={allData.follow12} />):('')}
                                {allData.follow13 ? (<img src={allData.follow13} />):('')}{allData.follow14 ? (<img src={allData.follow14} />):('')}{allData.follow15 ? (<img src={allData.follow15} />):('')}{allData.follow16 ? (<img src={allData.follow16} />):('')}
                                {allData.follow17 ? (<img src={allData.follow17} />):('')}{allData.follow18 ? (<img src={allData.follow18} />):('')}{allData.follow19 ? (<img src={allData.follow19} />):('')}{allData.follow29 ? (<img src={allData.follow20} />):('')}
                            </div>
                        </div>
                        <div className='listsItem' id='subsA' onClick={()=>chListItem('subs')}>
                            <div className='listsItemData'>
                                <p>{langData.subscriptions}</p>
                                <a>{allData.subs} {langData.people} <span>.</span> {allData.subsPost} {langData.posts}</a>
                            </div>
                            <div className='listsItemPp'>
                                {allData.subs1 ? (<img src={allData.subs1} />):('')}
                                {allData.subs2 ? (<img src={allData.subs2} />):('')}
                                {allData.subs3 ? (<img src={allData.subs3} />):('')}
                                {allData.subs4 ? (<img src={allData.subs4} />):('')}
                                {allData.subs5 ? (<img src={allData.subs5} />):('')}
                                {allData.subs6 ? (<img src={allData.subs6} />):('')}
                                {allData.subs7 ? (<img src={allData.subs7} />):('')}
                                {allData.subs8 ? (<img src={allData.subs8} />):('')}
                                {allData.subs9 ? (<img src={allData.subs9} />):('')}
                                {allData.subs10 ? (<img src={allData.subs10} />):('')}
                                {allData.subs11 ? (<img src={allData.subs11} />):('')}
                                {allData.subs12 ? (<img src={allData.subs12} />):('')}
                                {allData.subs13 ? (<img src={allData.subs13} />):('')}
                                {allData.subs14 ? (<img src={allData.subs14} />):('')}
                                {allData.subs15 ? (<img src={allData.subs15} />):('')}
                            </div>
                        </div>
                    </div>
                    <div id='bookmarks'>
                        <p>{langData.bookmarks}</p>
                    </div>
                </div>
                <div className='nowCollections'>
                    <div id='subscriptions'>
                        <div className='acHeader'>
                            <h3>{langData.users}</h3>
                        </div>
                        {/*<div className='acSelect'>
                            <a onClick={() => { ncCollect('subCreators') }} id="subCreatorsA" className='acSelectActive'>Creators</a>
                            <a onClick={() => { ncCollect('subPosts') }} id="subPostsA">Posts</a>
                            <div className='acSelectFilter' onClick={()=>opMenu('open')}><img src={require('../assets/icons/filter.webp')} /></div>
                        </div>*/}
                        <div className='ncItems' onClick={()=>opMenu('close')}>
                            <div id='subCreators'>
                                {creatorData ? (
                                <div>
                                    {creatorData.map(creator => 
                                    <div className='scUsers' key={creator.id}>
                                        <div className='scUser'>
                                            <div className='scUserHeader'>
                                                <img className='scuhBackground' src={creator.background} />
                                                <div className='scuhCover'></div>
                                                {/*<img className='scuhDots' src={require('../assets/icons/threedots.webp')} />*/}
                                            </div>
                                            <div className='scUserData'>
                                                <img src={creator.pp} />
                                                <p>{creator.name} {creator.surname}</p>
                                                <a href={'user-detail/'+creator.id}>@{creator.username}</a>
                                            </div>
                                            <div className='scUserFunctions'>
                                                <a href={'message/'+creator.muniq}><img src={require('../assets/icons/nMessage.webp')} /> {langData.message}</a>
                                                <a onClick={() => { getTip(creator.id, creator.id, creator.pp, creator.username, creator.name, creator.surname) }}><img src={require('../assets/icons/tip.webp')} /> {langData.send_tip}</a>
                                            </div>
                                            {/*<div className='scUserButtons'>
                                                <div className='upUserF wmt-10'>
                                                    <a id="followbtn">User Detail</a>
                                                </div>
                                                <div id="mmbfs1" className='upUserS'>
                                                    <a id="subbtn1">Subscribe </a>
                                                    <a id='subsbtn1' className="upUserSPrice">10€ per month</a>
                                                </div>
                                            </div>
                                            <p className='fl fw wmt-255'></p>*/}
                                            {/*<span>Last activity: Mar 14, 2024</span>*/}
                                            <p className='fl fw wmt-1 wmb-1'></p>
                                        </div>
                                    </div>
                                    )}
                                </div>
                                ):('')}
                            </div>
                            <div id='subPosts'>
                                <p>Sub posts</p>
                            </div>
                        </div>
                    </div>
                    <div id='following'>
                        <div className='acHeader'>
                            <h3>Following</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Notification;