import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
function Pages() {
    let { id } = useParams();
    let dil = localStorage.getItem('dil');
    let localDocs = localStorage.getItem('localDocs');
    const [pageData, setPageData] = useState([]);
    const fetchPageData = () => {
        fetch(localDocs+'/rn-adaptor/functions.php?id=' + id + '&islem=pages'+'&dil='+dil)
            .then(responsePage => responsePage.json())
            .then(pageData => setPageData(pageData))
            .catch(errorPage => console.error('Fetch error : ', errorPage));
    }
    useEffect(() => {
        fetchPageData();
    }, []);

    const parser = new DOMParser();
//console.log(pageData);
    return (
        <div>
            {pageData ? (
            <div className='pagesArea'>
                <h1>{pageData.title}</h1>
                {pageData.desc?(
                <div className='pagesAreaDesc'>
                    <div dangerouslySetInnerHTML={{ __html: pageData.desc }} />
                </div>
                ):''}
            </div>
            ):('')}
            <div className='loginAreaFooter'>
                <div className='lafLogo'>
                    <img src={require('../assets/images/klogo_dark.webp')} />
                </div>
                <div className='lafArea'>
                    <h5>Follow us on :</h5>
                    <a><img src={require('../assets/icons/copy.webp')} /> Instagram</a>
                    <a><img src={require('../assets/icons/copy.webp')} /> Twitter</a>
                    <a><img src={require('../assets/icons/copy.webp')} /> Tiktok</a>
                </div>
                <div className='lafArea'>
                    <h5>Account</h5>
                    <a>Login</a>
                    <a>Register</a>
                </div>
                <div className='lafArea'>
                    <h5>Company</h5>
                    <a>FAQ</a>
                    <a>Privacy Policy</a>
                    <a>Terms of Use</a>
                    <a>Cookie Policy</a>
                </div>
                <div className='lafArea'>
                    <h5>Contact Us</h5>
                    <a><img src={require('../assets/icons/copy.webp')} /> info@kinkyfans.net</a>
                </div>
                <div className='lafCopy'>
                    <p>&copy; 2024 KinkyFans</p>
                </div>
            </div>
        </div>
    )

}
export default Pages;