import React, { Component, useEffect, useState } from 'react';
import he from 'he';
/*import '../assets/css/store.css';*/
function menuOp(type, mid){
    if(type == "open"){document.getElementById(mid).style.display="block";}else{document.getElementById(mid).style.display="none";}
}
function Header({langData}) {
    const parser = new DOMParser();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let stockimg = localStorage.getItem('stockimg');
    let localDocs = localStorage.getItem('localDocs');
    const pp = require('../../assets/images/klogo.webp'); 

    const [getCats, setGetCats] = useState([]);
    const fetchGetCats = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions?islem=getCat&area=top&userid='+userid)
            .then(responseCat => responseCat.json())
            .then(getCats => setGetCats(getCats))
            .catch(errorCat => console.error('Fetch error : ', errorCat));
    }
    const [getStore, setGetStore] = useState([]);
    const fetchGetStore = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions?islem=storeMain')
            .then(responseStore => responseStore.json())
            .then(getStore => setGetStore(getStore))
            .catch(errorStore => console.error('Fetch error : ', errorStore));
    }
    const [settingData, setSettingData] = useState([]);
    const fetchSettingData = () => {
        fetch(localDocs+'/rn-adaptor/functions?islem=siteSetting')
            .then(responseSD => responseSD.json())
            .then(settingData => setSettingData(settingData))
            .catch(errorSD => console.log('Fetch error : ', errorSD));
    }
    useEffect(() => {
        fetchGetCats();
        fetchGetStore();
        fetchSettingData();
    }, []);
    //if(getCats[0]){console.log(getCats[0].cart);}
    //console.log(getCats);
    
    //console.log(getCats);
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          document.getElementById('searchBtn').click();
        }
      };
    return (
        <div className='storeHeader'>
            <div className='storeHeaderBand'>
                <a>{langData.store_head_text}</a>
            </div>
            <div className='storeHeaderTopMenu'>
                <div className='containers'>
                    <div className='spaceBetween'>
                        {settingData ? (
                        <div onClick={()=> window.location.href = '/store'} className='shmLogo'>
                            <img src={settingData.logo_dark} />
                        </div>
                        ):
                        <div onClick={()=> window.location.href = '/store'} className='shmLogo'>
                            <img src={getStore.logo} />
                        </div>
                        }
                        <div className='shmSearch'>
                            <input id='searchArea' placeholder={langData.store_search_text} onKeyDown={handleKeyDown}/>
                            <img id='searchBtn' onClick={()=>window.location.href= '/categories/search/'+document.getElementById('searchArea').value} src={require('../../assets/icons/search.webp')} />
                        </div>
                        {getCats ? (
                        <div className='shmFunction'>
                            <a><img src={require('../../assets/icons/profile.webp')} /></a>
                            <a href='/cart'><img src={require('../../assets/icons/cart.webp')} />{getCats[0] ?(<span id='cartCount'>{getCats[0].cart}</span>):<span id='cartCount'></span>}</a>
                        </div>
                        ):''}
                    </div>
                </div>
            </div>
            <div className='storeHeaderMenu'>
                <div className='containers'>
                    <div className='shmCenter'>
                        <a href='/categories'>{langData.just_getting_started}</a>
                        {/*<a>{langData.choose_your_path}</a>
                        <a>{langData.find_your_fetish}</a>
                        <a onMouseOver={()=>menuOp('open','menu1')} onMouseOut={()=>menuOp('close','menu1')} >For Men
                            <div id='menu1' className='shmSubMenu'>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                            </div>
                        </a>
                        <a onMouseOver={()=>menuOp('open','menu2')} onMouseOut={()=>menuOp('close','menu2')} >Explore It All
                            <div id='menu2' className='shmSubMenu'>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                                <a><img src={require('../../assets/images/product5.webp')} />Submenu</a>
                            </div>
                        </a>*/}
                        {getCats ? (
                        <div>
                            { getCats.map( category => 
                            <span key={category.id}>
                            {category.scats ? (
                            <a key={category.id} onMouseOver={()=>menuOp('open',category.id)} onMouseOut={()=>menuOp('close',category.id)} ><p onClick={()=>window.location.href='/categories/'+category.seo}>{(parser.parseFromString(he.decode(category.title), 'text/html')).documentElement.textContent}</p>
                                <div key={category.id} id={category.id} className='shmSubMenu'>
                                    {category.scats.map (scats =>
                                        <span key={scats.id} onClick={()=>window.location.href='/categories/'+scats.seo}><img src={scats.image} />{(parser.parseFromString(he.decode(scats.title), 'text/html')).documentElement.textContent}</span>
                                    )}
                                </div>
                            </a>
                            ):(
                            <a key={category.id} href={'/categories/'+category.seo} ><p>{(parser.parseFromString(he.decode(category.title), 'text/html')).documentElement.textContent}</p></a>
                            )}
                            </span>
                            )}
                        </div>
                        ):''}
                        {/*<a>{langData.sale}</a>*/}
                        <a href='/message/1'>{langData.help}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Header;
