import React, { Component, useEffect, useState } from 'react';
import he from 'he';
/*import '../assets/css/store.css';*/
function Header({langData}) {
    const parser = new DOMParser();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let stockimg = localStorage.getItem('stockimg');
    let localDocs = localStorage.getItem('localDocs');
    const [getFooter, setGetFooter] = useState([]);
    const fetchGetFooter = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions?islem=getFooter')
            .then(responseFooter => responseFooter.json())
            .then(getFooter => setGetFooter(getFooter))
            .catch(errorFooter => console.error('Fetch error : ', errorFooter));
    }
    
    const [settingData, setSettingData] = useState([]);
    const fetchSettingData = () => {
        fetch(localDocs+'/rn-adaptor/functions?islem=siteSetting')
            .then(responseSD => responseSD.json())
            .then(settingData => setSettingData(settingData))
            .catch(errorSD => console.log('Fetch error : ', errorSD));
    }
    useEffect(() => {
        fetchGetFooter();
        fetchSettingData();
    }, []);
    return (
        <div className='sbFooter'>
            <div className='containers'>
                <div className='sbFooterSocial'>
                    <h5>{langData.follow_us}</h5>
                    
                {settingData ? (
                <div>
                    {settingData.facebook?(<a href={settingData.facebook} target='_blank'><img src={require('../../assets/icons/facebook.webp')} /></a>):''}
                    {settingData.twitter?(<a href={settingData.twitter} target='_blank'><img src={require('../../assets/icons/x.webp')} /></a>):''}
                    {settingData.instagram?(<a href={settingData.instagram} target='_blank'><img src={require('../../assets/icons/insta.webp')} /></a>):''}
                    {settingData.tiktok?(<a href={settingData.tiktok} target='_blank'><img src={require('../../assets/icons/tiktok.webp')} /></a>):''}
                    {settingData.telegram?(<a href={settingData.telegram} target='_blank'><img src={require('../../assets/icons/telegram.webp')} /></a>):''}
                    {settingData.linkedin?(<a href={settingData.linkedin} target='_blank'><img src={require('../../assets/icons/in.webp')} /></a>):''}
                    {settingData.snapchat?(<a href={settingData.snapchat} target='_blank'><img src={require('../../assets/icons/snapchat.webp')} /></a>):''}
                    {settingData.twitch?(<a href={settingData.twitch} target='_blank'><img src={require('../../assets/icons/twitch.webp')} /></a>):''}
                </div>
                ):''}
                </div>
                {getFooter.lane1 ? (
                <div className='sbFooterItem'>
                    <h5>{langData.shop_by}</h5>
                    {getFooter.lane1.map( products => 
                    <a key={products.id} href={products.link}>{(parser.parseFromString(he.decode(products.title), 'text/html')).documentElement.textContent}</a>
                    )}
                </div>
                ):''}
                {getFooter.lane2 ? (
                <div className='sbFooterItem'>
                    <h5>{langData.categories}</h5>
                    {getFooter.lane2.map( categories => 
                    <a key={categories.id} href={categories.link}>{(parser.parseFromString(he.decode(categories.title), 'text/html')).documentElement.textContent}</a>
                    )}
                </div>
                ):''}
                {getFooter.lane2 ? (
                <div className='sbFooterItem'>
                    <h5>{langData.information}</h5>
                    {getFooter.lane3.map( datas => 
                    <a key={datas.id} href={datas.link}>{(parser.parseFromString(he.decode(datas.title), 'text/html')).documentElement.textContent}</a>
                    )}
                </div>
                ):''}
                <div className='sbFooterCopy'>
                    <p> &copy; 2024 Kinky Store, Powered by KinkyFans </p>
                </div>
            </div>
        </div>
    )
}
export default Header;
