import React, { Component, useEffect, useState } from 'react';
/*import '../assets/css/store.css';*/
import he from 'he';
import alertify from 'alertifyjs';
import StoreHead from './subComponents/StoreHead';
import StoreFooter from './subComponents/StoreFooter';
function Store( {langData} ) {
    const parser = new DOMParser();
    const pp = require('../assets/images/klogo.webp');
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let stockimg = localStorage.getItem('stockimg');
    let localDocs = localStorage.getItem('localDocs');
    const [getCart, setGetCart] = useState([]);
    
    const fetchGetCart = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions?islem=getCart&userid='+userid)
            .then(responseCart => responseCart.json())
            .then(getCart => setGetCart(getCart))
            .catch(errorCart => console.error('Fetch error : ', errorCart));
    }
    const [getWallet, setGetWallet] = useState([]);
    const fetchGetWallet = () => {
        fetch(localDocs+'/rn-adaptor/functions.php?islem=getWallet&userid=' + userid + '&dil=' + dil)
            .then(responseWallet => responseWallet.json())
            .then(getWallet => setGetWallet(getWallet))
            .catch(errorWallet => console.error('Fetch error : ', errorWallet));
    }
    useEffect(() => {
        fetchGetCart();
        fetchGetWallet();
    }, []);
    
    const checkoutFunc = async (userid) => {
        let eksikdata = 0;
        let name = document.getElementById('name').value;
        let surname = document.getElementById('surname').value;
        let email = document.getElementById('email').value;
        let phone = document.getElementById('phone').value;
        let state = document.getElementById('state').value;
        let district = document.getElementById('district').value;
        let address = document.getElementById('address').value;
        let orderNote = document.getElementById('orderNote').value;
        if(name == ""){eksikdata = 1;}
        if(surname == ""){eksikdata = 1;}
        if(email == ""){eksikdata = 1;}
        if(phone == ""){eksikdata = 1;}
        if(state == ""){eksikdata = 1;}
        if(district == ""){eksikdata = 1;}
        if(address == ""){eksikdata = 1;}
        if(eksikdata == 1){
            alertify.error("Please fill the all areas..", 2);
        }else{
            const addData = new FormData();
            addData.append('userid', userid);
            addData.append('name', name);
            addData.append('surname', surname);
            addData.append('email', email);
            addData.append('phone', phone);
            addData.append('state', state);
            addData.append('district', district);
            addData.append('address', address);
            addData.append('orderNote', orderNote);
            //console.log(addData)
            
            fetch(localDocs+'/rn-adaptor/sfunctions.php?islem=checkoutFunc', { method: 'POST', body: addData }).then(response => {console.log(response.json); 
                document.getElementById('shoppingCart').style.display = "none";
                document.getElementById('completeCheckout').style.display = "block";
            }).catch(error => { throw new Error('Error');console.log(error); });
            //window.location.href='/products';
        }
    };
    return (
        <div className='storePage'>
            <StoreHead datas={StoreHead} langData={langData} />

            <div className='containers'>
                <div className='breadcrump'>
                    <a href='/store'>{langData.home}</a> <a> > </a> <a>{langData.checkout}</a>
                </div>
                <div className='shoppingCart' id='shoppingCart'>
                    <div className='scLeft'>
                        <h1>{langData.checkout}</h1>
                        <form className='scForm'>
                            <div className='scFormItem'>
                                <label>{langData.name}</label>
                                <input id='name' type='text' />
                            </div>
                            <div className='scFormItem'>
                                <label>{langData.surname}</label>
                                <input id='surname' type='text' />
                            </div>
                            <div className='scFormItem'>
                                <label>{langData.mail}</label>
                                <input id='email' type='text' />
                            </div>
                            <div className='scFormItem'>
                                <label>{langData.phone}</label>
                                <input id='phone' type='text' />
                            </div>
                            <div className='scFormItem'>
                                <label>{langData.state}</label>
                                <input id='state' type='text' />
                            </div>
                            <div className='scFormItem'>
                                <label>{langData.district}</label>
                                <input id='district' type='text' />
                            </div>
                            <div className='scFormItem2'>
                                <label>{langData.address}</label>
                                <input id='address' type='text' />
                            </div>
                            <div className='scFormItem2'>
                                <label>{langData.order_note}</label>
                                <input id='orderNote' type='text' />
                            </div>
                        </form>
                    </div>
                    <div className='scRight'>
                        {getCart.products?(
                        <div className='scrTotals'>
                            {getCart.products.map ( products => 
                            <div className='scrTotal'>
                                <span>{(parser.parseFromString(he.decode(products.title), 'text/html')).documentElement.textContent} x {products.cartCount} </span>
                                <p>{products.total}$</p>
                            </div>
                            )}
                        </div>
                        ):'No products in the cart.'}
                        <div className='scrTotals'>
                            <div className='scrTotal'>
                                <span>{langData.total} </span>
                                <p>{getCart.total}$</p>
                            </div>
                            <div className='scrTotal'>
                                <span>{langData.coupon_code} </span>
                                <p>{getCart.coupon}$</p>
                            </div>
                            <div className='scrTotal'>
                                <span>{langData.shipping} </span>
                                <p>{getCart.cargo}$</p>
                            </div>
                            <div className='scrTotal'>
                                <span>{langData.total} </span>
                                <p>{getCart.ttotal}$</p>
                            </div>
                        </div>
                        <div className='paymentType'>
                            <p>{langData.payment_type}</p>
                            <select>
                                <option>Kinkyfans {langData.money}</option>
                                {/*<option>{langData.bank_transfer}</option>
                                <option>{langData.credit_card}</option>*/}
                            </select>
                            <p className='mt-3 mb-1'>{langData.balance} : 
                                <span style={{color:'orange'}}> {getWallet.wallet}</span>
                            </p>
                        </div>
                        <a onClick={()=>checkoutFunc(userid)} className='scrCheckout'>{langData.checkout}</a>
                    </div>
                </div>
                <div className='completeCheckout' id='completeCheckout'>
                    <div>
                        <img src={require('../assets/icons/complete.webp')} />
                        <a href='/categories'>{langData.categories}</a>
                        <a href='/transactions'>{langData.transactions}</a>
                    </div>
                    
                </div>
            </div>
            <StoreFooter datas={StoreFooter} langData={langData} />
        </div>
    )
}
export default Store;
