import React, { useEffect, useState } from 'react'
import he from 'he';
import { useParams } from 'react-router-dom';
import alertify from 'alertifyjs';
import Left from './Left';
import { LuAngry } from 'react-icons/lu';
/*const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});*/
function PostDetail({langData}) {
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let localDocs = localStorage.getItem('localDocs');
    const [applyData, setApplyData] = useState([]);
    const [getProfile, setGetProfile] = useState([]);
    const [selectedFile, setSelectedFile] = useState([]);
    const fetchGetProfile = () => {
        fetch(localDocs+'/rn-adaptor/l-apply?&userid=' + userid)
            .then(responseGP => responseGP.json())
            .then(getProfile => setGetProfile(getProfile))
            .catch(errorGP => console.error('Fetch error : ', errorGP));
    }
    useEffect(() => {
        fetchGetProfile();
        document.getElementById('applyPersonal').style.display="block";
    }, []);
    console.log(getProfile);
    const dataChange = async (area, value) => {
        if(area == "file"){value = selectedFile}
        const data = { userid: userid, area: area, value: value};
        try {
            const response = await fetch(localDocs+'/rn-adaptor/o-chApply.php', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const responseJson = await response.json();
            console.log("PHP tarafından dönen yanıt:", responseJson);
            /*if (responseJson.status == 0) { alertify.error(responseJson.message, 2); }
            if (responseJson.status == 1) { alertify.success(responseJson.message, 2); }*/
        } catch (error) { console.log("An error :", error); }
    };
    const handleFileChange = (event) => {
        console.log(event.target.files[0]);
        setSelectedFile(event.target.files[0]);
        //setSelectedFile(document.getElementById('file').files[0]);
        //dataChange('file', 'file');
        handleSubmit(event);
      };
      const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedFile) {
          const formData = new FormData();
          formData.append('file', selectedFile);formData.append('userid', userid);formData.append('file', 'file');formData.append('area','file');
          fetch(localDocs+'/rn-adaptor/o-chApply.php', {
            method: 'POST',
            body: formData
          })
          .then(response => {console.log(response);})
          .catch(error => {console.log(error);});
        }
      };
    const [text, setText] = useState([]);
    //function textLoad(){setText(getProfile.bio);}
    const emojis = ["😀", "😁", "😅", "😄", "😂", "🤣", "🥰", "😍", "💑", "😎", "🤔", "😠", "😞", "😪", "😭", "🎈"];
    const addEmoji = (emoji) => { setText(text + emoji); }
    const textChange = (event) => { selectGetir();setText(event.target.value); dataChange('description', document.getElementById('bio').value);}
    const usernameChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, username: document.getElementById('username').value }); dataChange('username', document.getElementById('username').value);};
    const nameChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, name:  document.getElementById('name').value }); dataChange('name', document.getElementById('name').value);};
    const surnameChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, surname:  document.getElementById('surname').value }); dataChange('surname', document.getElementById('surname').value);};
    const birthdayChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, birthday:  document.getElementById('birthday').value }); dataChange('birthday', document.getElementById('birthday').value);};
    const facebookChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, facebook:  document.getElementById('facebook').value }); dataChange('facebook', document.getElementById('facebook').value);};
    const instagramChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, instagram:  document.getElementById('instagram').value }); dataChange('instagram', document.getElementById('instagram').value);};
    const twitterChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, twitter:  document.getElementById('twitter').value }); dataChange('twitter', document.getElementById('twitter').value);};
    const tiktokChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, tiktok:  document.getElementById('tiktok').value }); dataChange('tiktok', document.getElementById('tiktok').value);};
    const snapchatChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, snapchat:  document.getElementById('snapchat').value }); dataChange('snapchat', document.getElementById('snapchat').value);};
    const telegramChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, telegram:  document.getElementById('telegram').value }); dataChange('telegram', document.getElementById('telegram').value);};
    const twitchChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, twitch:  document.getElementById('twitch').value }); dataChange('twitch', document.getElementById('twitch').value);};
    const addressChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, address:  document.getElementById('address').value }); dataChange('address', document.getElementById('address').value);};
    const cityChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, city:  document.getElementById('city').value }); dataChange('city', document.getElementById('city').value);};
    const countryChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, country:  document.getElementById('country').value }); dataChange('country', document.getElementById('country').value);};
    const stateChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, state:  document.getElementById('state').value }); dataChange('state', document.getElementById('state').value);};
    const postcodeChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, post_code:  document.getElementById('post_code').value }); dataChange('postcode', document.getElementById('post_code').value);};
    const ratingChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, rating:  document.getElementById('rating').value }); dataChange('rating', document.getElementById('rating').value);};
    const genderChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, gender:  document.getElementById('gender').value }); dataChange('gender', document.getElementById('gender').value);};
    const sexChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, sex:  document.getElementById('sex').value }); dataChange('sex', document.getElementById('sex').value);};
    const ageChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, age:  document.getElementById('age').value }); dataChange('age_value', document.getElementById('age').value);};
    const eyeChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, eye:  document.getElementById('eye').value }); dataChange('eye', document.getElementById('eye').value);};
    const hairChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, hair:  document.getElementById('hair').value }); dataChange('hair', document.getElementById('hair').value);};
    const heightChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, height:  document.getElementById('height').value }); dataChange('height', document.getElementById('height').value);};
    const shoeChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, shoe:  document.getElementById('shoe').value }); dataChange('shoe', document.getElementById('shoe').value);};
    const ethnicityChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, ethnicity:  document.getElementById('ethnicity').value }); dataChange('ethnicity', document.getElementById('ethnicity').value);};
    const tattooChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, tattoo:  document.getElementById('tattoo').value }); dataChange('tattoo', document.getElementById('tattoo').value);};
    const piercingChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, piercing:  document.getElementById('piercing').value }); dataChange('piercing', document.getElementById('piercing').value);};
    const memberfeeChange = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, memberfee:  document.getElementById('memberfee').value }); dataChange('memberfee', document.getElementById('memberfee').value);};
    const memberfee2Change = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, memberfee2:  document.getElementById('memberfee2').value }); dataChange('memberfee2', document.getElementById('memberfee2').value);};
    const memberfee3Change = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, memberfee3:  document.getElementById('memberfee3').value }); dataChange('memberfee3', document.getElementById('memberfee3').value);};
    const memberfee6Change = (event) => {setGetProfile({rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, memberfee6:  document.getElementById('memberfee6').value }); dataChange('memberfee6', document.getElementById('memberfee6').value);};
    function selectGetir(){
        setGetProfile({ rating:  document.getElementById('rating').value, gender:  document.getElementById('gender').value, sex:  document.getElementById('sex').value, hair:  document.getElementById('hair').value, eye:  document.getElementById('eye').value, ethnicity:  document.getElementById('ethnicity').value, 
    });
        setGetProfile({ gender:  document.getElementById('gender').value });
        setGetProfile({ sex:  document.getElementById('sex').value });
        setGetProfile({ eye:  document.getElementById('eye').value });
        setGetProfile({ hair:  document.getElementById('hair').value });
        setGetProfile({ eye:  document.getElementById('eye').value });
        setGetProfile({ ethnicity:  document.getElementById('ethnicity').value });
    }
    if (getProfile.piercing == '1') { document.getElementById('piercing').checked = "true"; }
    if (getProfile.tattoo == '1') { document.getElementById('tattoo').checked = "true"; }let ltabid = 'profile';
    function pdTab(tabid) {
        document.getElementById('profile').style.display = "none";document.getElementById('profileA').classList.remove('editProfileActive');
        document.getElementById('account').style.display = "none";document.getElementById('accountA').classList.remove('editProfileActive');
        document.getElementById('kinkys').style.display = "none";document.getElementById('kinkysA').classList.remove('editProfileActive');
        document.getElementById('notifications').style.display = "none";document.getElementById('notificationsA').classList.remove('editProfileActive');
        document.getElementById(ltabid).style.display = "none"; document.getElementById(tabid).style.display = "block";
        document.getElementById(ltabid + 'A').classList.remove('editProfileActive'); document.getElementById(tabid + 'A').classList.add('editProfileActive');
        ltabid = tabid;
    }
    
    function areaOpen(area){
        document.getElementById('applyPersonal').style.display = "none";
        document.getElementById('applyMailing').style.display = "none";
        document.getElementById('applyAboutme').style.display = "none";
        document.getElementById('applyVerification').style.display = "none";
        document.getElementById(area).style.display = "block";
    }
    return (
        <div>
            <div className='leftAreaC'>
                <Left title="Title" />
            </div>
            <div className='fullAreaC'>
                {/*
                <div className='allCollections'>
                    <div className='acHeader'>
                        <h3>Settings</h3>
                    </div>
                    <div className='editProfile'>
                        <a id='profileA' onClick={() => pdTab('profile')} className='editProfileActive'>Profile<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a id='accountA' onClick={() => pdTab('account')}>Account<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a id='kinkysA' onClick={() => pdTab('kinkys')}>Kinks<img src={require('../assets/icons/arrow.webp')} /></a>
                        <a id='notificationsA' onClick={() => pdTab('notifications')}>Notifications<img src={require('../assets/icons/arrow.webp')} /></a>
                    </div>
                </div>*/}
                <div className='midArea'>
                    <div className='applyArea'>
                        <div className='stHeader'>
                            <a onClick={()=>window.history.back()} className='leftArrow webitem'><img src={require('../assets/icons/arrow.webp')} /></a>
                            <h3>{langData.apply_as_creator}</h3>
                        </div>
                        <div className='applyDetails' id='applyPersonal'>
                            <h3>{langData.personal_information}</h3>
                            <div className='adAreaNorm'>
                                <label>{langData.username}</label>
                                <input className='adaFwInput' id="username" value={getProfile.username} onChange={() => usernameChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.name}</label>
                                <input className='adaFwInput' id="name"  value={getProfile.name} onChange={() => nameChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.surname}</label>
                                <input className='adaFwInput' id="surname"  value={getProfile.surname} onChange={() => surnameChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.birthday}</label>
                                <input type="date" className='adaFwInput' id="birthday"  value={getProfile.birthday} onChange={() => birthdayChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>Facebook</label>
                                <input className='adaFwInput' id="facebook"  value={getProfile.facebook} onChange={() => facebookChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>Instagram</label>
                                <input className='adaFwInput' id="instagram"  value={getProfile.instagram} onChange={() => instagramChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>X(Twitter)</label>
                                <input className='adaFwInput' id="twitter"  value={getProfile.twitter} onChange={() => twitterChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>Tiktok</label>
                                <input className='adaFwInput' id="tiktok"  value={getProfile.tiktok} onChange={() => tiktokChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>Snapchat</label>
                                <input className='adaFwInput' id="snapchat"  value={getProfile.snapchat} onChange={() => snapchatChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>Telegram</label>
                                <input className='adaFwInput' id="telegram"  value={getProfile.telegram} onChange={() => telegramChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>Twitch</label>
                                <input className='adaFwInput' id="twitch"  value={getProfile.twitch} onChange={() => twitchChange()}/>
                            </div>
                            <div className='adAreafw'>
                                <label>{langData.bio_description}</label>
                                <div id={"emoji1"} className='bioEmojiArea'>
                                    {emojis.map((emoji) => (
                                        <a key={emoji} onClick={() => addEmoji(emoji)} style={{ margin: '5px' }}>{emoji}</a>
                                    ))}
                                </div>
                                <div className='bioArea'>
                                    <textarea id={'bio'} value={text} onChange={textChange} placeholder={langData.bio_description}></textarea>
                                    <a onClick={() => { document.getElementById('emoji1').style.display === "block" ? (document.getElementById('emoji1').style.display = "none") : (document.getElementById('emoji1').style.display = "block") }
                                    } className='emoji'><img src={require("../assets/icons/messageEmoji.webp")} /></a>
                                </div>
                            </div>
                            {/*<div className='adArea'>
                                <label>E-Mail</label>
                                <input className='adaFwInput' id="email" />
                            </div>
                            <div className='adArea'>
                                <label>Phone</label>
                                <input className='adaFwInput' id="phone" />
                            </div>*/}
                            <a id='personalNext' onClick={()=>areaOpen('applyMailing')} className='adaSend'>{langData.next}</a>
                        </div>
                        <div className='applyDetails' id='applyMailing'>
                            <h3>Mailing Address</h3>
                            <div className='adAreafw'>
                                <label>{langData.address}</label>
                                <input className='adaFwInput' id="address" value={getProfile.address} onChange={() => addressChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.city}</label>
                                <input className='adaFwInput' id="city" value={getProfile.city} onChange={() => cityChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.country}</label>
                                <input className='adaFwInput' id="country" value={getProfile.country} onChange={() => countryChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.state}</label>
                                <input type="text" className='adaFwInput' id="state" value={getProfile.state} onChange={() => stateChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.post_code}</label>
                                <input type="text" className='adaFwInput' id="post_code" value={getProfile.postcode} onChange={() => postcodeChange()}/>
                            </div>
                            <h3>{langData.member_fee}</h3>
                            <div className='adAreaNorm'>
                                <label>{langData.member_fee} (1 {langData.month})</label>
                                <input className='adaFwInput' id="memberfee" value={getProfile.memberfee} onChange={() => memberfeeChange()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.member_fee} (2 {langData.month})</label>
                                <input className='adaFwInput' id="memberfee2" value={getProfile.memberfee2} onChange={() => memberfee2Change()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.member_fee} (3 {langData.month})</label>
                                <input className='adaFwInput' id="memberfee3" value={getProfile.memberfee3} onChange={() => memberfee3Change()}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.member_fee} (6 {langData.month})</label>
                                <input className='adaFwInput' id="memberfee6" value={getProfile.memberfee6} onChange={() => memberfee6Change()}/>
                            </div>
                            <a id='mailingNext' onClick={()=>areaOpen('applyAboutme')} className='adaSend'>{langData.next}</a>
                            <a id='mailingBack' onClick={()=>areaOpen('applyPersonal')} className='adaBack'>{langData.back}</a>
                        </div>
                        <div className='applyDetails' id='applyAboutme'>
                            <h3>{langData.about_me}</h3>
                            <div className='adAreaNorm'>
                                <label>{langData.rating}</label>
                                <select className='adaSelect' id='rating' onChange={() => ratingChange()}>
                                    <option value="0">{langData.choose}</option>
                                    {getProfile.rating == "SFW"?(<option selected value='SFW'>SFW</option>):(<option value='SFW'>SFW</option>)}
                                    {getProfile.rating == "NSFW"?(<option selected value='NSFW'>NSFW</option>):(<option value='NSFW'>NSFW</option>)}
                                </select>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.gender}</label>
                                <select className='adaSelect' id='gender' onChange={() => genderChange()}>
                                    <option value="0">{langData.choose}</option>
                                    {getProfile.gender == "Male"?(<option selected value='Male'>{langData.male}</option>):(<option value='Male'>{langData.male}</option>)}
                                    {getProfile.gender == "Female"?(<option selected value='Female'>{langData.female}</option>):(<option value='Female'>{langData.female}</option>)}
                                    {getProfile.gender == "Trans"?(<option selected value='Trans'>{langData.trans}</option>):(<option value='Trans'>{langData.trans}</option>)}
                                    {getProfile.gender == "Non-Binary"?(<option selected value='Non-Binary'>{langData.non_binary}</option>):(<option value='Non-Binary'>{langData.non_binary}</option>)}
                                    {getProfile.gender == "Agency"?(<option selected value='Agency'>{langData.agency}</option>):(<option value='Agency'>{langData.agency}</option>)}
                                </select>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.sexual_orientation}</label>
                                <select className='adaSelect' id='sex' onChange={() => sexChange()}>
                                    <option value="0">{langData.choose}</option>
                                    {getProfile.sex == "Heterosexual/Straight"?(<option selected value='Heterosexual/Straight'>{langData.heterosexual_straight}</option>):(<option value='Heterosexual/Straight'>{langData.heterosexual_straight}</option>)}
                                    {getProfile.sex == "Homosexual"?(<option selected value='Homosexual'>{langData.homosexual}</option>):(<option value='Homosexual'>{langData.homosexual}</option>)}
                                    {getProfile.sex == "Bisexual"?(<option selected value='Bisexual'>{langData.bisexual}</option>):(<option value='Bisexual'>{langData.bisexual}</option>)}
                                    {getProfile.sex == "Heteroflexible"?(<option selected value='Heteroflexible'>{langData.heteroflexible}</option>):(<option value='Heteroflexible'>{langData.heteroflexible}</option>)}
                                    {getProfile.sex == "Polysexual"?(<option selected value='Polysexual'>{langData.polysexual}</option>):(<option value='Polysexual'>{langData.polysexual}</option>)}
                                    {getProfile.sex == "Queer"?(<option selected value='Queer'>{langData.queer}</option>):(<option value='Queer'>{langData.queer}</option>)}
                                    {getProfile.sex == "Androgynosexual"?(<option selected value='Androgynosexual'>{langData.androgynosexual}</option>):(<option value='Androgynosexual'>{langData.androgynosexual}</option>)}
                                    {getProfile.sex == "Androsexual"?(<option selected value='Androsexual'>{langData.androsexual}</option>):(<option value='Androsexual'>{langData.androsexual}</option>)}
                                    {getProfile.sex == "Gray-A"?(<option selected value='Gray-A'>{langData.gray_a}</option>):(<option value='Gray-A'>{langData.gray_a}</option>)}
                                    {getProfile.sex == "Gynosexual"?(<option selected value='Gynosexual'>{langData.gynosexual}</option>):(<option value='Gynosexual'>{langData.gynosexual}</option>)}
                                    {getProfile.sex == "Objectumsexual"?(<option selected value='Objectumsexual'>{langData.objectumsexual}</option>):(<option value='Objectumsexual'>{langData.objectumsexual}</option>)}
                                    {getProfile.sex == "Pansexual/Omnisexual"?(<option selected value='Pansexual/Omnisexual'>{langData.pansexual_omnisexual}</option>):(<option value='Pansexual/Omnisexual'>{langData.pansexual_omnisexual}</option>)}
                                    {getProfile.sex == "Skolisexual"?(<option selected value='Skolisexual'>{langData.skolisexual}</option>):(<option value='Skolisexual'>{langData.skolisexual}</option>)}
                                </select>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.age}</label>
                                <input type="number" onChange={() => ageChange()} className='adaFwInput' id="age" min='18' max='80' value={getProfile.age}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.eye_color}</label>
                                <select className='adaSelect' id='eye' onChange={() => eyeChange()}>
                                    <option value="0">{langData.choose}</option>
                                    {getProfile.eye == "Amber"?(<option selected value='Amber'>{langData.amber}</option>):(<option value='Amber'>{langData.amber}</option>)}
                                    {getProfile.eye == "Blue"?(<option selected value='Blue'>{langData.blue}</option>):(<option value='Blue'>{langData.blue}</option>)}
                                    {getProfile.eye == "Brown"?(<option selected value='Brown'>{langData.brown}</option>):(<option value='Brown'>{langData.brown}</option>)}
                                    {getProfile.eye == "Green"?(<option selected value='Green'>{langData.green}</option>):(<option value='Green'>{langData.green}</option>)}
                                    {getProfile.eye == "Grey"?(<option selected value='Grey'>{langData.grey}</option>):(<option value='Grey'>{langData.grey}</option>)}
                                    {getProfile.eye == "Hazel"?(<option selected value='Hazel'>{langData.hazel}</option>):(<option value='Hazel'>{langData.hazel}</option>)}
                                    {getProfile.eye == "Red"?(<option selected value='Red'>{langData.red}</option>):(<option value='Red'>{langData.red}</option>)}
                                    {getProfile.eye == "Violet"?(<option selected value='Violet'>{langData.violet}</option>):(<option value='Violet'>{langData.violet}</option>)}
                                    {getProfile.eye == "Mixed"?(<option selected value='Mixed'>{langData.mixed}</option>):(<option value='Mixed'>{langData.mixed}</option>)}
                                </select>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.hair_color}</label>
                                <select className='adaSelect' id='hair' onChange={() => hairChange()}>
                                    <option value="0">{langData.choose}</option>
                                    {getProfile.hair == "Black"?(<option selected value='Black'>{langData.black}</option>):(<option value='Black'>{langData.black}</option>)}
                                    {getProfile.hair == "Blonde"?(<option selected value='Blonde'>{langData.blonde}</option>):(<option value='Blonde'>{langData.blonde}</option>)}
                                    {getProfile.hair == "Dark Blonde"?(<option selected value='Dark Blonde'>{langData.dark_blonde}</option>):(<option value='Dark Blonde'>{langData.dark_blonde}</option>)}
                                    {getProfile.hair == "Platinum Blonde"?(<option selected value='Platinum Blonde'>{langData.platinum_blonde}</option>):(<option value='Platinum Blonde'>{langData.platinum_blonde}</option>)}
                                    {getProfile.hair == "Brunette"?(<option selected value='Brunette'>{langData.brunette}</option>):(<option value='Brunette'>{langData.brunette}</option>)}
                                    {getProfile.hair == "Dark Brown"?(<option selected value='Dark Brown'>{langData.dark_brown}</option>):(<option value='Dark Brown'>{langData.dark_brown}</option>)}
                                    {getProfile.hair == "Red Head"?(<option selected value='Red Head'>{langData.red_head}</option>):(<option value='Red Head'>{langData.red_head}</option>)}
                                    {getProfile.hair == "Auburn"?(<option selected value='Auburn'>{langData.auburn}</option>):(<option value='Auburn'>{langData.auburn}</option>)}
                                    {getProfile.hair == "Blue"?(<option selected value='Blue'>{langData.blue}</option>):(<option value='Blue'>{langData.blue}</option>)}
                                    {getProfile.hair == "Grey"?(<option selected value='Grey'>{langData.grey}</option>):(<option value='Grey'>{langData.grey}</option>)}
                                    {getProfile.hair == "Pink"?(<option selected value='Pink'>{langData.pink}</option>):(<option value='Pink'>{langData.pink}</option>)}
                                    {getProfile.hair == "Purple"?(<option selected value='Purple'>{langData.purple}</option>):(<option value='Purple'>{langData.purple}</option>)}
                                    {getProfile.hair == "Orange"?(<option selected value='Orange'>{langData.orange}</option>):(<option value='Orange'>{langData.orange}</option>)}
                                    {getProfile.hair == "Many"?(<option selected value='Many'>{langData.many}</option>):(<option value='Many'>{langData.many}</option>)}
                                </select>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.height}(cm)</label>
                                <input type="text" className='adaFwInput' id="height"  onChange={() => heightChange()} value={getProfile.height}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.shoe_size}</label>
                                <input type="number" onChange={() => shoeChange()} className='adaFwInput' id="shoe" min='4' max='14'  value={getProfile.shoe}/>
                            </div>
                            <div className='adAreaNorm'>
                                <label>{langData.ethnicity}</label>
                                <select className='adaSelect' id='ethnicity' onChange={() => ethnicityChange()}>
                                    <option value="0">{langData.choose}</option>
                                    {getProfile.ethnicity == "White/Caucasian"?(<option selected value='White/Caucasian'>{langData.white_caucasian}</option>):(<option value='White/Caucasian'>{langData.white_caucasian}</option>)}
                                    {getProfile.ethnicity == "Latino/Hispanic"?(<option selected value='Latino/Hispanic'>{langData.latino_hispanic}</option>):(<option value='Latino/Hispanic'>{langData.latino_hispanic}</option>)}
                                    {getProfile.ethnicity == "Asian"?(<option selected value='Asian'>{langData.asian}</option>):(<option value='Asian'>{langData.asian}</option>)}
                                    {getProfile.ethnicity == "Black/Ebony"?(<option selected value='Black/Ebony'>{langData.black_ebony}</option>):(<option value='Black/Ebony'>{langData.black_ebony}</option>)}
                                    {getProfile.ethnicity == "Native American/Alaskan"?(<option selected value='Native American/Alaskan'>{langData.native_american_alaskan}</option>):(<option value='Native American/Alaskan'>{langData.native_american_alaskan}</option>)}
                                    {getProfile.ethnicity == "Pacific Islander"?(<option selected value='Pacific Islander'>{langData.pacific_islander}</option>):(<option value='Pacific Islander'>{langData.pacific_islander}</option>)}
                                    {getProfile.ethnicity == "Middle Eastern"?(<option selected value='Middle Eastern'>{langData.middle_eastern}</option>):(<option value='Middle Eastern'>{langData.middle_eastern}</option>)}
                                    {getProfile.ethnicity == "East Indian"?(<option selected value='East Indian'>{langData.east_indian}</option>):(<option value='East Indian'>{langData.east_indian}</option>)}
                                    {getProfile.ethnicity == "Other"?(<option selected value='Other'>{langData.other}</option>):(<option value='Other'>{langData.other}</option>)}
                                </select>
                            </div>
                            <div className='adAreaSwitch'>
                                <label>{langData.tattoos}</label>
                                <input className="kink-switch" id='tattoo' type="checkbox"  onChange={() => tattooChange()}/>
                            </div>
                            <div className='adAreaSwitch'>
                                <label>{langData.piercing}</label>
                                <input className="kink-switch" id='piercing' type="checkbox"  onChange={() => piercingChange()}/>
                            </div>
                            <a id='aboutmeNext' onClick={()=>areaOpen('applyVerification')} className='adaSend'>{langData.next}</a>
                            <a id='aboutmeBack' onClick={()=>areaOpen('applyMailing')} className='adaBack'>{langData.back}</a>
                        </div>
                        <div className='applyDetails' id='applyVerification'>
                            <h3>{langData.identity_verification}</h3>
                            <div className='adAreafw adafw'>
                                <input type="file" id='file' onChange={(event)=>{handleFileChange(event);}} className='adaFile'/>
                            </div>
                            <a onClick={()=>window.location.href='profile'} className='adaSend'>{langData.save}</a>
                            <a id='aboutmeBack' onClick={()=>areaOpen('applyAboutme')} className='adaBack'>{langData.back}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default PostDetail;