import React, { Component, useEffect, useState } from 'react';
/*import '../assets/css/store.css';*/
import he from 'he';
import alertify from 'alertifyjs';
import { useParams } from 'react-router-dom';
import StoreHead from './subComponents/StoreHead';
import StoreFooter from './subComponents/StoreFooter';
let selectedColor = "0";
let selectedSize = "0";
function Store({langData}) {
    let { id } = useParams();
    const parser = new DOMParser();
    let userid = localStorage.getItem('userid');
    let dil = localStorage.getItem('dil');
    let stockimg = localStorage.getItem('stockimg');
    let localDocs = localStorage.getItem('localDocs');
    const [userData, setUserData] = useState([]);
    const pp = require('../assets/images/klogo.webp');
    
    let lastImg = "main1";
    function chImage(gid){
        document.getElementById(gid).classList.add('idlaSelected');document.getElementById(lastImg).classList.remove('idlaSelected');
        document.getElementById('mainImg').src = document.getElementById(gid).src;
        lastImg = gid;
    }
    const [getProduct, setGetProduct] = useState([]);
    const fetchGetProduct = () => {
        fetch(localDocs+'/rn-adaptor/sfunctions?islem=getProduct&id='+id+'&selectedColor='+selectedColor)
            .then(responseProduct => responseProduct.json())
            .then(getProduct => {setGetProduct(getProduct);if(getProduct.status == "0"){window.location.href='/categories';}})
            .catch(errorProduct => console.error('Fetch error : ', errorProduct));
    }
    
    useEffect(() => {
        fetchGetProduct();
    }, []);
    function chColor(){selectedColor = document.getElementById('colors').value;fetchGetProduct();}
    function chSize(){selectedSize = document.getElementById('sizes').value;}

    const addFunc = async (urunid, userid) => {
        let quantity = document.getElementById('quantity').value;
        //document.getElementById('cartCount').innerHTML = quantity;
        let eksikalan = 0;
        if(getProduct.colors){if(selectedColor == "0"){eksikalan = 1;}}
        if(getProduct.sizes){if(selectedSize == "0"){eksikalan = 2;}}
        if (eksikalan > 0) { if(eksikalan == 1){alertify.error("Please select the color.");}if(eksikalan == 2){alertify.error("Please select the color and size.");} }
        else {
            console.log(urunid+' - Color : '+selectedColor+' - Size : '+selectedSize+' - Qua : '+quantity);
            const data = { userid: userid, urunid: urunid, quantity: quantity, color : selectedColor, size : selectedSize };
            try {
                const response = await fetch(localDocs+'/rn-adaptor/sfunctions?islem=addCart', {
                    method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
                });
                if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
                const plJson = await response.json();
                if (plJson.status == 0) { alertify.error(plJson.message, 2); }
                if (plJson.status == 1) {alertify.success(plJson.message, 2);}
                document.getElementById('cartCount').innerHTML = plJson.cart;
                console.log(plJson);
                console.log("PHP tarafından dönen yanıt:", plJson);
            } catch (error) { console.log("An error :", error); }
        }
    };

    return (
        <div className='storePage'>
            <StoreHead datas={StoreHead} langData={langData} />
{getProduct ? (
            <div className='containers'>
                <div className='breadcrump'>
                    <a href='/store'>{langData.home}</a> <a> > </a> <a>
                    {getProduct.title ? ((parser.parseFromString(he.decode(getProduct.title), 'text/html')).documentElement.textContent):''}
                    </a>
                </div>
                <div className='itemDetails'>
                    <h1>{getProduct.title ? ((parser.parseFromString(he.decode(getProduct.title), 'text/html')).documentElement.textContent):''}</h1>
                    <div className='idLeftArea'>
                        <div className='idlaImages'>
                            {getProduct.images ? (
                            <div className='idlaImagesGroup'>
                                <img onClick={()=>chImage('main1')} id='main1' className='idlaSelected' src={getProduct.image} />
                                {getProduct.images.map ( images =>
                                        <img key={images.id} onClick={()=>chImage('img'+images.id)} id={'img'+images.id} src={images.image} />
                                )}
                                {/*<img onClick={()=>chImage('img2')} id='img2' src={require('../assets/images/product2.webp')} />
                                <img onClick={()=>chImage('img3')} id='img3' src={require('../assets/images/product3.webp')} />
                                <img onClick={()=>chImage('img4')} id='img4' src={require('../assets/images/product4.webp')} />
                            <img onClick={()=>chImage('img5')} id='img5' src={require('../assets/images/product5.webp')} />*/}
                            </div>
                            ):''}
                            <div className='idlaMainImage'>
                                <img id='mainImg' src={getProduct.image} />
                            </div>
                        </div>
                        <div className='idlaDesc'>
                            <h3>{langData.description}</h3>
                            {getProduct.desc ? (<p>{(parser.parseFromString(he.decode(getProduct.desc), 'text/html')).documentElement.textContent}</p>):''}
                        </div>
                    </div>
                    <div className='idRightArea'>
                        {getProduct.top_desc ? (<p>{(parser.parseFromString(he.decode(getProduct.top_desc), 'text/html')).documentElement.textContent}</p>):''}
                        {getProduct.colors ? (
                        <div className='idraSection'>
                            <label>{langData.color}</label>
                            <select id='colors' onChange={()=>chColor()} defaultValue='0'>
                                <option value='0'>Choose</option>
                                {getProduct.colors.map( colors => 
                                <option key={colors.id} value={colors.title}>{colors.title}</option>
                                )}
                            </select>
                        </div>
                        ):''}
                        {getProduct.sizes ? (
                        <div className='idraSection'>
                            <label>{langData.size}</label>
                            <select id='sizes' onChange={()=>chSize()} defaultValue='0'>
                                <option value='0'>Choose</option>
                                {getProduct.sizes.map( sizes => 
                                <option key={sizes.id} value={sizes.title}>{sizes.title}</option>
                                )}
                            </select>
                        </div>
                        ):''}
                        <h5>${getProduct.price}</h5>
                        <div className='idraFunction'>
                            <input type='number' id='quantity' defaultValue='1'/>
                            <a onClick={()=>addFunc(id,userid)}>{langData.add_to_cart}</a>
                        </div>
                    </div>
                    {getProduct.others ? (
                    <div className='idFullArea'>
                        <h3>{langData.you_may_also_like}</h3>
                        {getProduct.others.map( products => 
                        <div key={products.id} onClick={()=>window.location.href = '/product/'+products.id} className='idItems'>
                            <div className='idItem'>
                                <img src={products.image} />
                                <p>{products.title} </p>
                                {products.discount > 0 ?(
                                <div>
                                    <span className='priceNew'>{products.discount}$</span>
                                    <span className='priceOld'>{products.price}$</span>
                                </div>
                                ):(
                                <div>
                                    <span className='priceNew'>{products.price}$</span>
                                </div>
                                )}
                            </div>
                        </div>
                        )}
                    </div>
                    ):''}
                </div>

            </div>
):''}
            <StoreFooter datas={StoreFooter} langData={langData} />
        </div>
    )
}
export default Store;
