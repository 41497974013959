import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import alertify from 'alertifyjs';
import { useGoogleLogin } from  "@stack-pulse/next-google-login"
import axios from 'axios';
import OAuth from 'oauth-1.0a';
import sha1 from 'js-sha1';
import { GoogleLogin } from 'react-google-login';
import { GoogleAuthProvider } from 'firebase/auth/web-extension';
import { signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';

import {
    LoginSocialGoogle,
    LoginSocialAmazon,
    LoginSocialFacebook,
    LoginSocialGithub,
    LoginSocialInstagram,
    LoginSocialLinkedin,
    LoginSocialMicrosoft,
    LoginSocialPinterest,
    LoginSocialTwitter,
    LoginSocialApple,
    IResolveParams,
  } from 'reactjs-social-login';
  
  import {
    FacebookLoginButton,
    GoogleLoginButton,
    GithubLoginButton,
    AmazonLoginButton,
    InstagramLoginButton,
    LinkedInLoginButton,
    MicrosoftLoginButton,
    TwitterLoginButton,
    AppleLoginButton,
  } from 'react-social-login-buttons'


function Login() {
    let { uniqid, type } = useParams();
    let localDocs = localStorage.getItem('localDocs');
    const [suggestData, setSuggestData] = useState([]);
    const [forgotData, setForgotData] = useState([]);
    let suserid = 1;
    const fetchSuggestData = () => {
        fetch(localDocs+'/rn-adaptor/l-suggestions?id=' + suserid+'&type=login')
            .then(responseSGG => responseSGG.json())
            .then(suggestData => setSuggestData(suggestData))
            .catch(errorSGG => console.log('Fetch error : ', errorSGG));
    }
    useEffect(() => {
        fetchSuggestData();
    }, []);
    const fetchForgotData = () => {
        fetch(localDocs+'/rn-adaptor/functions?uniqid=' + uniqid+'&islem=isForgot')
            .then(responseForgot => responseForgot.json())
            .then(forgotData => setForgotData(forgotData))
            .catch(errorForgot => console.log('Fetch error : ', errorForgot));
    }
    useEffect(() => {
        fetchSuggestData();
        fetchForgotData();
    }, []);

if(type == "reset"){
    setTimeout(() => {
        if(forgotData){
            document.getElementById('signupForm').style.display = "none";
            document.getElementById('loginForm').style.display = "none";
            document.getElementById('forgotForm').style.display = "block";
            if(forgotData.status == 1){
                document.getElementById('firstForgot').style.display="none";
                document.getElementById('twinceForgot').style.display="block";
                document.getElementById('forgotText').style.display = 'block';
                document.getElementById('forgotText').style.color = 'yellowgreen';
                document.getElementById('forgotText').innerHTML=forgotData.email;
            }else{
                document.getElementById('firstForgot').style.display="block";
                document.getElementById('twinceForgot').style.display="none";
                document.getElementById('forgotText').style.display = 'block';
                document.getElementById('forgotText').innerHTML="Your password reset link has expired.";
            }
        }    
        console.log(uniqid);
    }, 500);
    
}
if(type == "agency"){
    setTimeout(() => {
        document.getElementById('signupForm').style.display = "block";
        document.getElementById('loginForm').style.display = "none";
        document.getElementById('forgotForm').style.display = "none";
        document.getElementById('agencyNo').value=uniqid;
        console.log(uniqid);
    }, 500);

}
    //eraseCookie('username'); // 'username' çerezini sil      
    const loginFunc = async (uniqid) => {
        let username = document.getElementById('username').value;
        let password = document.getElementById('password').value;
        if (username == "" || password == "") { alertify.error("Please fill the areas."); } else {
            const data = { username: username, password: password, uniqid: uniqid };
            try {
                const response = await fetch(localDocs+'/rn-adaptor/functions.php?islem=login', {
                    method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
                });
                if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
                const plJson = await response.json();
                if (plJson.status == 0) { alertify.error(plJson.message, 2); }
                if (plJson.status == 1) {
                    //console.log(uniqid);
                    alertify.success(plJson.message, 2);
                    setInterval(() => {
                        window.location.href = "/" + plJson.userid;
                    }, 2000);
                }
                console.log("PHP tarafından dönen yanıt:", plJson);
            } catch (error) { console.log("An error :", error); }
        }
    };
    const forgotFunc = async (uniqid,type) => {
        let canGo = 1;
        let forgotMail = document.getElementById('forgotMail').value;
        let forgotPassword = document.getElementById('forgotPassword').value;
        let forgotRePassword = document.getElementById('forgotRePassword').value;
        
        if(type == "sendMail"){
            if (forgotMail == "") { alertify.error("Please fill the areas."); canGo = 0;} 
        }
        if(type == "enterPass"){
            if (forgotPassword == "" || forgotRePassword == "") { alertify.error("Please fill the areas."); canGo = 0;} 
            if(forgotPassword != forgotRePassword){alertify.error("Passwords doesnt match.");canGo = 0;}
        }
        if(canGo == 1){
            const data = { forgotMail: forgotMail, forgotPassword: forgotPassword, forgotRePassword: forgotRePassword, type: type, uniqid: uniqid };
            try {
                const response = await fetch(localDocs+'/rn-adaptor/functions.php?islem=forgotFunc', {
                    method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
                });
                if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
                const plJson = await response.json();
                if (plJson.status == 0) { alertify.error(plJson.message, 2); }
                if (plJson.status == 1) {
                    alertify.success(plJson.message, 2);
                    setInterval(() => {window.location.href = "/login";}, 1000);
                }
                console.log("PHP tarafından dönen yanıt:", plJson);
            } catch (error) { console.log("An error :", error); }
        }
    };
    const registerFunc = async () => {
        let agencyNo = document.getElementById('agencyNo').value;
        let regUsername = document.getElementById('regUsername').value;
        let regEmail = document.getElementById('regEmail').value;
        let name = document.getElementById('name').value;
        let surname = document.getElementById('surname').value;
        let regPassword = document.getElementById('regPassword').value;
        let rePassword = document.getElementById('rePassword').value;
        if (regUsername == '' || regEmail == '' || name == '' || surname == '' || regPassword == '' || rePassword == '') { alertify.error("Please fill the areas."); } else {
            const data = { username: regUsername, email: regEmail, name: name, surname: surname, password: regPassword, repassword: rePassword, agencyNo : agencyNo };
            try {
                const response = await fetch(localDocs+'/rn-adaptor/functions.php?islem=register', {
                    method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
                });
                if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
                const plJson = await response.json();
                if (plJson.status == 0) { alertify.error(plJson.message, 2); }
                if (plJson.status == 1) {
                    alertify.success(plJson.message, 2);
                    document.getElementById('memberOk').style.display = "block";
                    document.getElementById('memberOk').innerHTML = plJson.message;
                    setInterval(() => {
                        window.location.href='/login';
                    }, 3000);
                }
                console.log("PHP tarafından dönen yanıt:", plJson);
            } catch (error) { console.log("An error :", error); }
        }
    };
    function opForm(area) {
        document.getElementById('signupForm').style.display = "none";
        document.getElementById('loginForm').style.display = "none";
        document.getElementById('forgotForm').style.display = "none";
        let farea = document.getElementById(area);
        if (farea.style.display == "block") {
            farea.style.display = "none";
        } else {
            farea.style.display = "block";
        }
    }
    let t_goodle_id = "193172779023-e8t3lms3nq2r7lgv4pvi7ftdf6qqc0of.apps.googleusercontent.com";
    const { signIn } =  useGoogleLogin({
        clientId: "1046719410980-g6cj8rmdg9jlu1fhj0sk9sk74ktoi3gt.apps.googleusercontent.com",
        onSuccess: async (tokenResponse) => {
        const data = { 
            name : tokenResponse.profileObj.name,
            email : tokenResponse.profileObj.email,
            familyName : tokenResponse.profileObj.familyName,
            givenName : tokenResponse.profileObj.givenName,
            googleId : tokenResponse.profileObj.googleId,
            imageUrl : tokenResponse.profileObj.imageUrl,
                };
        try {
            const response = await fetch(localDocs+'/rn-adaptor/functions.php?islem=gLogin', {
                method: 'POST', headers: { 'Content-Type': 'application/json', }, body: JSON.stringify(data)
            });
            if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }
            const plJson = await response.json();
            if (plJson.status == 0) { alertify.error(plJson.message, 2); }
            if (plJson.status == 1) {
                //console.log(uniqid);
                alertify.success(plJson.message, 2);
                setInterval(() => {
                    window.location.href = "/" + plJson.userid;
                }, 2000);
            }
            console.log("PHP tarafından dönen yanıt:", plJson);
        } catch (error) { console.log("An error :", error); }
    }});
    const handleTwitterLogin = async () => {
        const oauth = OAuth({
          consumer: {key: 'AaC0smUvDsf5Zo16t5CGUdhE1',secret: 'LOOpazxDrwvTkQjz1P5Ln6Jj0OpQq4R6buCj1FxBJPqsiLXuWw'},
          signature_method: 'HMAC-SHA1',
          hash_function(base_string, key) {
            return new Promise((resolve, reject) => {
              crypto.subtle.importKey('raw',new TextEncoder().encode(key),{ name: 'HMAC', hash: 'SHA-1' },false,['sign']
              ).then(cryptoKey => {crypto.subtle.sign('HMAC', cryptoKey, new TextEncoder().encode(base_string)).then(hash => {resolve(btoa(String.fromCharCode(...new Uint8Array(hash))));}).catch(error => reject(error));}).catch(error => reject(error));
            });
          }
        });
        const requestData = {url: 'https://api.x.com/oauth/request_token',method: 'POST'};
        const requestTokenResponse = await axios.post(requestData.url, oauth.authorize(requestData));
        const requestToken = requestTokenResponse.data.match(/oauth_token=([^&]+)/)[1];
        window.location.href = `https://api.x.com/oauth/authenticate?oauth_token=${requestToken}`;
      };
      const REDIRECT_URI = window.location.href;
      const REACT_APP_TWITTER_V2_APP_KEY = "AaC0smUvDsf5Zo16t5CGUdhE1";
      const REACT_APP_TWITTER_V2_APP_SECRET = "LOOpazxDrwvTkQjz1P5Ln6Jj0OpQq4R6buCj1FxBJPqsiLXuWw";
    const [provider, setProvider] = useState('')
    const [profile, setProfile] = useState(null)

    const onLoginStart = useCallback(() => {
        //alert('login start')
        console.log('login start');
    }, [])

    const onLogoutSuccess = useCallback(() => {
        setProfile(null)
        setProvider('')
        alert('logout success')
    }, []);
    
    let t_app_key="AaC0smUvDsf5Zo16t5CGUdhE1";
    let t_app_secret="LOOpazxDrwvTkQjz1P5Ln6Jj0OpQq4R6buCj1FxBJPqsiLXuWw";

    const handleGoogle = async (e) => {
        const priver = new GoogleAuthProvider();
        return signInWithPopup(auth, provider)
    }
    return (
        <div className='loginArea'>
            <div className='loginAreaTop'>
                <div className='latLogo'>
                    <img src={require('../assets/images/klogo.webp')} />
                    <a>Explore</a>
                </div>
                <div className='latButton'>
                    <a><span onClick={() => opForm('loginForm')}>Login</span>
                        <div className='loginForm' id='loginForm'>
                            <div className='lfHeader'>
                                <img src={require('../assets/images/klogo.webp')} />
                                <h3>Login</h3>
                            </div>
                            <div className='lfForm'>
                                <input type='text' id='username' placeholder='Email/Username'/>
                                <input type='password' id='password' placeholder='Password'/>
                                <a onClick={() => loginFunc()}>Login</a>
                                <div className='lfFormCheck'>
                                    <input type='checkbox' />
                                    <span>Stay logged in for 30 Days</span>
                                    <a onClick={() => opForm('forgotForm')}>Forgot Your Password</a>
                                </div>
                                <div className='lfFormOther'>
                                    <p onClick={() => opForm('signupForm')} className='lfSignUp'>Don't have an account yet? <span>Create a free account</span></p>
                                    <h6>or signup with</h6>
                                    <a onClick={() =>  signIn()}><img src={require('../assets/icons/copy.webp')} /> Google </a>
                                    {/*<a onClick={handleTwitterLogin()}><img src={require('../assets/icons/copy.webp')} /> X</a>*/}
                                    <a onClick={()=>document.getElementById('tLogBtn').click()}><img src={require('../assets/icons/copy.webp')} /> X</a>
                                </div>

                                <div className='lfFormOther'>
                                    <button onClick={handleGoogle}>Yeni Google</button>
                                <LoginSocialTwitter
                                    isOnlyGetToken
                                    client_id={t_app_key}
                                    client_key={t_app_secret}
                                    redirect_uri={'/login'}
                                    onLoginStart={onLoginStart}
                                    onResolve={({ provider, data }) => {
                                    setProvider(provider)
                                    setProfile(data)
                                    }}
                                    onReject={(err) => {
                                    console.log(err)
                                    }}
                                >
                                    <a style={{display:"none"}} id='tLogBtn'><img src={require('../assets/icons/copy.webp')} /> X</a>
                                </LoginSocialTwitter>
                                </div>
                            </div>
                        </div>
                        <div className='loginForm' id='forgotForm'>
                            <div className='lfHeader'>
                                <img src={require('../assets/images/klogo.webp')} />
                                <h3>Forgot</h3>
                            </div>
                            <div id='forgotText'></div>
                            <div className='lfForm' id='firstForgot'>
                                <input type='text' id='forgotMail' placeholder='Email'/>
                                <a onClick={() => forgotFunc(uniqid,'sendMail')}>Send</a>
                            </div>
                            <div style={{display : "none"}} className='lfForm' id='twinceForgot'>
                                <input type='text' id='forgotPassword' placeholder='Password'/>
                                <input type='text' id='forgotRePassword' placeholder='Re-Password'/>
                                <a onClick={() => forgotFunc(uniqid,'enterPass')}>Send</a>
                            </div>
                        </div>
                        <div className='loginForm' id='signupForm'>
                            <div className='lfHeader'>
                                <img src={require('../assets/images/klogo.webp')} />
                                <h3>Register</h3>
                            </div>
                            <div className='lfForm'>
                                <p id='memberOk'>Membership application has been completed, please confirm your e-mail address.</p>
                                <input type='text' id='agencyNo' placeholder='AgencyNo' defaultValue='0' />
                                <input type='text' id='regUsername' placeholder='Username' />
                                <input type='text' id='regEmail' placeholder='Email' />
                                <input type='text' id='name' placeholder='Name' />
                                <input type='text' id='surname' placeholder='Surname' />
                                <input type='password' id='regPassword' placeholder='Password' />
                                <input type='password' id='rePassword' placeholder='RePassword' />
                                <a onClick={() => registerFunc()}>Register</a>

                                <div className='lfFormOther'>
                                    <h6>or signup with</h6>
                                    <a onClick={() =>  signIn()}><img src={require('../assets/icons/copy.webp')} /> Google</a>
                                    <a><img src={require('../assets/icons/copy.webp')} /> X</a>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a><span onClick={() => opForm('signupForm')}>Signup</span>

                    </a>
                </div>
            </div>
            <div className='LoginAreaBanner'>
                <img src={require('../assets/images/lback4.webp')} />
                <h1>Your Favorite Creators</h1>
            </div>
            <div className='loginAreaCreators'>
                <h2>Featured Creators</h2>
                <div className='lacUsers'>
                    <div id="lacUsers">
                        {suggestData.map(user =>
                            <a href={'/user-detail/' + user.id} key={user.id} className='lacUser'>
                                <img src={user.pp} />
                                <div className='lacUserDatas'>
                                    <p>{user.name} {user.surname}</p>
                                    <a href={'/user-detail/' + user.id} className='lacUserLink'>@{user.username}</a>
                                    <div className='lacUserData'>
                                        <a><img src={require('../assets/icons/posts.webp')} />{user.timg}</a>
                                        <a><img src={require('../assets/icons/video.webp')} />{user.tvid}</a>
                                    </div>
                                </div>
                            </a>
                        )}
                    </div>
                    {/*<div className='lacSm'><a>See More</a></div>*/}
                </div>
            </div>
            <div className='loginAreaFooter'>
                <div className='lafLogo'>
                    <img src={require('../assets/images/klogo_dark.webp')} />
                </div>
                <div className='lafArea'>
                    <h5>Follow us on :</h5>
                    <a href='https://twitter.com/kinkyfans_2023' target='_blank'><img src={require('../assets/icons/copy.webp')} /> Twitter</a>
                    <a href='https://www.instagram.com/kinkyfans2023' target='_blank'><img src={require('../assets/icons/copy.webp')} /> Instagram</a>
                    <a href='https://www.tiktok.com/@kinkyfans.net' target='_blank'><img src={require('../assets/icons/copy.webp')} /> Tiktok</a>
                    <a href='https://telegram.me/kinkyfans' target='_blank'><img src={require('../assets/icons/copy.webp')} /> Telegram</a>
                </div>
                <div className='lafArea'>
                    <h5>Account</h5>
                    <a href='/login'>Login</a>
                    <a href='/login'>Register</a>
                </div>
                <div className='lafArea'>
                    <h5>Company</h5>
                    <a href='/pages/21' target="_blank">Impressium</a>
                    <a href='/pages/16' target="_blank">Cookie Policy</a>
                    <a href='/pages/17' target="_blank">Privacy Policy</a>
                </div>
                <div className='lafArea'>
                    <h5>Contact Us</h5>
                    <a href='mailto:info@kinkyfans.net'><img src={require('../assets/icons/copy.webp')} /> info@kinkyfans.net</a>
                </div>
                <div className='lafCopy'>
                    <p>&copy; 2024 KinkyFans</p>
                </div>
            </div>
        </div>
    )

}
export default Login;